import {NxLoader} from '@nextbank/ui-components';
import isNil from 'lodash/isNil';
import React, {useContext} from 'react';
import {PhaseName} from '../../../../../constants/api-urls';
import useLoanApplicationDataOptions from '../../../../../shared/hooks/use-loan-application-data-options.hook';
import useSingleQuery from '../../../../../shared/hooks/use-single-query.hook';
import {ApiHelper} from '../../../../../utils/api-helper';
import {LoanConfigurationContext} from '../../LoanConfiguration';
import {LoanApplicationDataPhase} from './loan-application-data-phase.model';
import LoanApplicationDataForm from './LoanApplicationDataForm';

export default function LoanApplicationData(): React.ReactElement {

  const {process, isProcessUpdating} = useContext(LoanConfigurationContext);
  const phaseUrl = ApiHelper.getPhaseConfigurationUrl(process.id, PhaseName.LOAN_APPLICATION_DATA);
  const config = useSingleQuery<LoanApplicationDataPhase>(phaseUrl, null, !isProcessUpdating);
  const loanApplicationDataOptions = useLoanApplicationDataOptions(process.loanProduct, config);

  return isNil(config) || !loanApplicationDataOptions.areOptionsLoaded || isProcessUpdating
    ? <NxLoader />
    : <LoanApplicationDataForm config={config} loanApplicationDataOptions={loanApplicationDataOptions} />;
}
