import {NxLoader} from '@nextbank/ui-components';
import isNil from 'lodash/isNil';
import React, {useContext} from 'react';
import {PhaseName} from '../../../../../constants/api-urls';
import useCalculatorDictionaryEntries from '../../../../../shared/hooks/use-calculator-dictionary-entries.hook';
import usePaymentIntervalOptions from '../../../../../shared/hooks/use-payment-interval-options.hook';
import useSingleQuery from '../../../../../shared/hooks/use-single-query.hook';
import {ApiHelper} from '../../../../../utils/api-helper';
import {SelectHelper} from '../../../../../utils/select-helper';
import {TransHelper} from '../../../../../utils/trans-helper';
import {LoanConfigurationContext} from '../../LoanConfiguration';
import {CalculatorPhase} from './calculator-phase.model';
import CalculatorForm from './CalculatorForm';

export const PrefixTrans = TransHelper.getPrefixedTrans('COMMON.FIELDS');
const {mapToStringOptions} = SelectHelper;

export default function Calculator(): React.ReactElement {

  const {process, isProcessUpdating} = useContext(LoanConfigurationContext);
  const phaseUrl = ApiHelper.getPhaseConfigurationUrl(process.id, PhaseName.CALCULATOR);
  const config = useSingleQuery<CalculatorPhase>(phaseUrl, null, !isProcessUpdating);

  const {dictionaryEntries, areDictionariesLoaded} = useCalculatorDictionaryEntries(config);
  const advanceInterestApplicationOptions = mapToStringOptions(dictionaryEntries.advanceInterestApplication);
  const uidApplicationOptions = mapToStringOptions(dictionaryEntries.uidApplication);
  const creationTypeOptions = mapToStringOptions(dictionaryEntries.creationType);
  const paymentIntervalOptionsProps = usePaymentIntervalOptions(process.loanProduct, dictionaryEntries.paymentInterval);

  const simulationParametersOptions = {
    creationType: creationTypeOptions,
    advanceInterestApplication: advanceInterestApplicationOptions,
    uidApplicationOptions: uidApplicationOptions,
    paymentIntervalOptionsProps: paymentIntervalOptionsProps
  };

  return isProcessUpdating || isNil(config) || !areDictionariesLoaded ? <NxLoader /> : (
    <CalculatorForm config={config} loanParametersOptions={simulationParametersOptions} />
  );
}
