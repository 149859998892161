import {NxButton, NxSelect, NxNumberInput} from '@nextbank/ui-components';
import {cloneDeep} from 'lodash';
import React, {ReactElement} from 'react';
import {Trans} from 'react-i18next';
import {PaymentIntervalOptionsProps} from '../../../../../../../shared/hooks/use-payment-interval-options.hook';
import {generateKey} from '../../../../../../../utils/generate-key';
import BinButton from '../../../../../../shared/bin-button/BinButton';
import {PrefixTrans} from '../../Calculator';
import {KeyedFixedPaymentOption} from '../../calculator-phase.model';
import styles from './FixedPayment.module.scss';

export enum PaymentField {
  TOTAL_AMORTIZATION_NUMBER = 'totalAmortizationNumber',
  PAYMENT_INTERVAL = 'paymentIntervalId'
}

interface Props {
  disabled?: boolean;
  fixedPaymentsState: {
    fixedPaymentOptions: KeyedFixedPaymentOption[];
    setFixedPaymentOptions?: (fields: KeyedFixedPaymentOption[]) => void;
  };
  paymentIntervalOptionsProps: PaymentIntervalOptionsProps;
}

export default function FixedPayments({fixedPaymentsState, paymentIntervalOptionsProps, disabled = false}: Props): ReactElement {

  const {paymentIntervalOptions, getIsSinglePaymentInterval} = paymentIntervalOptionsProps;
  const {fixedPaymentOptions, setFixedPaymentOptions} = fixedPaymentsState;

  const setFields = (fields: KeyedFixedPaymentOption[]): void => {
    if (setFixedPaymentOptions) {
      setFixedPaymentOptions(fields);
    }
  }

  const addFixedPayment = (): void => setFields([...fixedPaymentOptions, {...generateKey()}]);

  const removeField = (key: string): () => void => (): void => {
    const fields = cloneDeep(fixedPaymentOptions);
    const updatedFieldIndex = fields.findIndex(field => field.key === key);
    fields.splice(updatedFieldIndex, 1);
    setFields(fields)
  };

  const handlePaymentIntervalChange = (fieldKey: string): (intervalId: string | null) => void =>
    (intervalId: string | null): void => {

      const fields = cloneDeep(fixedPaymentOptions);
      const updatedFieldIndex = fields.findIndex(({key}) => key === fieldKey);

      fields[updatedFieldIndex] = {
        ...fields[updatedFieldIndex],
        [PaymentField.PAYMENT_INTERVAL]: Number(intervalId),
        [PaymentField.TOTAL_AMORTIZATION_NUMBER]: getIsSinglePaymentInterval(intervalId ?? undefined) ? 1 : undefined
      };

      setFields(fields);
    };

  const handleTotalAmortizationChange = (fieldKey: string): (value: number | null) => void =>
    (value: number | null): void => {

      const fields = cloneDeep(fixedPaymentOptions);
      const updatedFieldIndex = fields.findIndex(({key}) => key === fieldKey);

      fields[updatedFieldIndex] = {
        ...fields[updatedFieldIndex],
        [PaymentField.TOTAL_AMORTIZATION_NUMBER]: value ?? undefined
      };

      setFields(fields);
    };

  return (
    <>
      {
        fixedPaymentOptions
          .map((field, index) => (
            <div className={styles.field} key={field.key}>
              <div className={styles.inputs}>
                <NxSelect value={field.paymentIntervalId?.toString()}
                          className={styles.input}
                          label={<PrefixTrans>PAYMENT_INTERVAL</PrefixTrans>}
                          onChange={handlePaymentIntervalChange(field.key)}
                          options={paymentIntervalOptions ?? []}
                          disabled={disabled}/>
                <NxNumberInput value={field.totalAmortizationNumber}
                               className={styles.input}
                               label={<PrefixTrans>TOTAL_AMORTIZATION_NUMBER</PrefixTrans>}
                               onChange={handleTotalAmortizationChange(field.key)}
                               disabled={disabled || getIsSinglePaymentInterval(field.paymentIntervalId?.toString())}
                               min={0} />
              </div>
              {
                index > 0 && <BinButton onClick={removeField(field.key)} disabled={disabled} bordered={false} />
              }
            </div>
        ))
      }
      {
        !disabled &&
          <div className={styles.addRow}>
            <NxButton onClick={addFixedPayment}>
              <Trans>COMMON.ADD_NEW</Trans>
            </NxButton>
          </div>
      }
    </>
  );
}
