import {NxFormikDatePicker, NxFormikInput, NxFormikSelect} from '@nextbank/ui-components';
import React, {ReactElement} from 'react';
import {ACCEPTED_DOCUMENT_FILE_TYPES} from '../../../../../../constants/file-uploads';
import {DictionaryEntryRequirement} from '../../../../../../shared/model/dictionary-requirement.model';
import {DictionaryEntry} from '../../../../../../shared/model/dictionary.model';
import {SelectHelper} from '../../../../../../utils/select-helper';
import {TransHelper} from '../../../../../../utils/trans-helper';
import FormColumn from '../../../../../shared/form-column/FormColumn';
import FormGroup from '../../../../../shared/form-column/FormGroup';
import {ConfiguredFieldWrapper} from '../../shared/configured-fields/ConfiguredFieldWrapper';
import {FieldDisablementReason} from '../../shared/configured-fields/field-disablement-reason.model';
import RequirementFormHeader from '../shared/RequirementFormHeader';
import {IdDocument} from './id-document.model';
import styles from './IdDocumentForm.module.scss';
import {ScanIdsFileInput} from './ScanIdsFileInput';

export interface IdDocumentFormEntries {
  country?: DictionaryEntry[],
  idDocumentRequirement?: DictionaryEntry[]
}

interface Props {
  /**
   * parentFieldPath: string representation of path in object for eg.: borrower.idDocuments[1].number.
   */
  formPath: string;
  entry: DictionaryEntryRequirement;
  entries: IdDocumentFormEntries;
  deleteFunction: (typeId: number) => void;
  disablementReason?: FieldDisablementReason;
  disabled?: boolean;
}

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_APPLICATIONS.CUSTOMER_DATA.DOCUMENTS');

export default function IdDocumentForm(
  {entry, entries, formPath, deleteFunction, disabled, disablementReason}: Props): ReactElement {

  // Variable defines field requirement. All form fields using this variable are required if requirement changed
  // check validation schema too
  const required = true;
  const getName = (key: keyof IdDocument<File>): string => `${formPath}.${key}`;
  const countryOptions = SelectHelper.mapToNumberOptions(entries.country);

  const Header =
    <RequirementFormHeader disabled={disabled}
                           entry={entry}
                           entries={entries.idDocumentRequirement}
                           deleteFunction={deleteFunction} />;

  return (
    <FormColumn>
      <FormGroup className={styles.inputs} header={Header}>
        <ConfiguredFieldWrapper disablementReason={disablementReason}>
          <NxFormikInput label={<PrefixTrans>NUMBER</PrefixTrans>}
                         name={getName('number')}
                         disabled={disabled}
                         required={required} />
        </ConfiguredFieldWrapper>
        <ConfiguredFieldWrapper disablementReason={disablementReason}>
          <NxFormikSelect<number> label={<PrefixTrans>COUNTRY</PrefixTrans>}
                                  name={getName('countryId')}
                                  options={countryOptions ?? []}
                                  disabled={disabled}
                                  emptyOption />
        </ConfiguredFieldWrapper>
        <ConfiguredFieldWrapper disablementReason={disablementReason}>
          <NxFormikDatePicker label={<PrefixTrans>EXPIRATION_DATE</PrefixTrans>}
                              name={getName('expirationDate')}
                              disabled={disabled} />
        </ConfiguredFieldWrapper>
        <ConfiguredFieldWrapper disablementReason={disablementReason}>
          <NxFormikDatePicker label={<PrefixTrans>ISSUED_ON</PrefixTrans>}
                              name={getName('issuedOn')}
                              disabled={disabled}/>
        </ConfiguredFieldWrapper>

        <ConfiguredFieldWrapper disablementReason={disablementReason}>
          <NxFormikInput label={<PrefixTrans>ISSUING_AUTHORITY</PrefixTrans>}
                         name={getName('issuingAuthority')}
                         disabled={disabled}/>
        </ConfiguredFieldWrapper>
        <ConfiguredFieldWrapper disablementReason={FieldDisablementReason.VALUE_CALCULATED_AUTOMATICALLY}>
          <NxFormikInput label={<PrefixTrans>DATE_SUBMITTED</PrefixTrans>}
                         name={getName('dateSubmitted')}
                         disabled={true} />
        </ConfiguredFieldWrapper>
        <ConfiguredFieldWrapper disablementReason={disablementReason}>
          <ScanIdsFileInput name={getName('scans')}
                            className={styles.fileUpload}
                            label={<PrefixTrans>SCANS</PrefixTrans>}
                            acceptedFileTypes={ACCEPTED_DOCUMENT_FILE_TYPES}
                            disabled={disabled}/>
        </ConfiguredFieldWrapper>
      </FormGroup>
    </FormColumn>
  );
}
