import {isArray, isEmpty, isNil, isObject} from 'lodash';
import {DownloadFileFunctionType, UploadFileFunctionType} from '../../../../../../shared/hooks/use-file-upload.hook';
import {Check, CheckType} from '../../../../../../shared/model/check.model';
import {DocumentField, FieldType, isDocumentField} from '../../../../../../shared/model/field.model';
import {isNxFile, NxFile} from '../../../../../../shared/model/nx-file.model';
import {isDictionaryType, isMultiDictionary} from '../../../../../../utils/custom-fields-utils';
import {handleFileUpload} from '../../../../../../utils/file-upload-utils';
import {StringHelper} from '../../../../../../utils/string-helper';
import {CustomFieldValue, CustomFieldValueTO} from '../../customer-data/custom-fields/custom-field.model';
import {CheckValue} from './check-value.model';

/*
*  Single and multi dictionary type checks comes from BE as array cast to strings (f.e.: '[123, 234]' or `[13]`).
*  They have to be parsed to string or array of strings.
*/
export const parseSavedCheck = (savedValue?: string, type?: FieldType): string | number | string[] | undefined => {

  if (isNil(savedValue)) {
    return isMultiDictionary(type) ? [] : undefined;
  }

  if (!isDictionaryType(type)) {
    return savedValue;
  }

  const trimmedValue = savedValue.replace(/[[\]\s]/gi, '');

  return isMultiDictionary(type) ? trimmedValue.split(',') : trimmedValue;
};

type FieldValue =
  CustomFieldValue
  | CustomFieldValue<NxFile>
  | CheckValue
  | CheckValue<NxFile>
  | CheckValue<DocumentField>;

export const handleNxFiles = async (customFieldValue: CustomFieldValue | CheckValue,
                                    downloadFile: DownloadFileFunctionType): Promise<FieldValue> => {

  const {fieldType, value} = customFieldValue;

  if (!value) {
    return customFieldValue;
  }

  if (fieldType === FieldType.FILE) {
    const fileBasicData = JSON.parse(value as string);
    const file = await downloadFile(fileBasicData);
    return {...customFieldValue, value: file};
  }

  if (fieldType === FieldType.DOCUMENT) {
    const documentFieldValue = JSON.parse(value as string);
    const file = await downloadFile(documentFieldValue.file);
    return {...customFieldValue, value: {...documentFieldValue, file}};
  }

  return customFieldValue;

};

export const handleCustomFieldValues = (values: FieldValue[],
                                        uploadFile: UploadFileFunctionType): Promise<CustomFieldValueTO>[] =>
  values.filter(({value}) => {

      if (isNxFile(value)) {
        return !isNil((value as NxFile).file);
      }

      if (isDocumentField(value) && isNxFile(value.file)) {
        return !isNil(value?.file);
      }

      if (typeof value === 'string' && !StringHelper.isBlank(value)) {
        return true;
      }

      if (typeof value === 'number' && !Number.isNaN(value)) {
        return true;
      }

      // filters empty array or array with at least one empty value
      if (isArray(value) && (StringHelper.isBlank(value) || value.filter(elem => StringHelper.isBlank(elem)).length > 0)) {
        return false;
      }

      return isObject(value) || !isEmpty(value);
    })
    .map(async field => {

      const value = field.value;

      if (isNxFile(value)) {
        const fileBasicData = await handleFileUpload(value, uploadFile);
        return {...field, value: JSON.stringify(fileBasicData)} as CustomFieldValueTO;
      }

      if (isDocumentField(value) && isNxFile(value.file)) {
        const fileBasicData = await handleFileUpload(value.file, uploadFile);
        return {...field, value: JSON.stringify({...value, file: fileBasicData})} as CustomFieldValueTO;
      }

      return {...field, value: String(value)} as CustomFieldValueTO;
    });

export const getCustomCheckName = (checkValues: CheckValue<unknown>[], id?: number): string => {
  const fieldIndex = checkValues.findIndex(({checkId}) => checkId === id);
  return `checkValues[${fieldIndex}]`;
};

export const isCheckManual = (check: Check): boolean => check.type === CheckType.MANUAL;
