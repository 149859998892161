import isNil from 'lodash/isNil';
import {useEffect} from 'react';
import {CALCULATE_TERM_URL} from '../../../../../../../constants/api-urls';
import useGet from '../../../../../../../shared/hooks/use-get.hook';
import {DictionaryEntry} from '../../../../../../../shared/model/dictionary.model';
import {SetFieldValue} from '../../../../../../../shared/model/formik.model';
import {DictionaryHelper} from '../../../../../../../utils/dictionary-helper';
import {CalculatorFormFields} from '../../../calculator/calculator.model';

const {getEntryCodeById} = DictionaryHelper;

export function useCalculateTerm(values: CalculatorFormFields,
                                 setFieldValue: SetFieldValue,
                                 paymentIntervalDictionaryEntries?: DictionaryEntry[]): void {

  const getCalculateTerm = useGet<string>(CALCULATE_TERM_URL);

  useEffect(() => {
    const totalAmortizationNumber = values.totalAmortizationNumber.value;
    const paymentInterval = values.paymentInterval.value;

    if (!isNil(totalAmortizationNumber) && paymentInterval && paymentIntervalDictionaryEntries) {
      const urlSearchParams = getUrlParams(paymentInterval, totalAmortizationNumber, paymentIntervalDictionaryEntries);
      getCalculateTerm(urlSearchParams).then(term => setFieldValue('term', term));
    }
  }, [values.totalAmortizationNumber.value, values.paymentInterval.value]);
}

export function getUrlParams(paymentIntervalValue: string,
                      totalAmortizationNumber: number,
                      paymentIntervalDictionaryEntries: DictionaryEntry[]): URLSearchParams {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.set('paymentInterval', getEntryCodeById(paymentIntervalValue, paymentIntervalDictionaryEntries));
  urlSearchParams.set('amortizationNumber', totalAmortizationNumber.toString());
  return urlSearchParams;
}
