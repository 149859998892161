import {NxFormikNumberInput, NxFormikSelect} from '@nextbank/ui-components';
import {useField} from 'formik';
import React, {ReactElement, useEffect} from 'react';
import {DEFAULT_VALUE} from '../../../../../../constants/static-field-values';
import {PaymentIntervalOptionsProps} from '../../../../../../shared/hooks/use-payment-interval-options.hook';
import {Field} from '../../../../../../shared/model/field.model';
import FormColumn from '../../../../../shared/form-column/FormColumn';
import {PrefixTrans} from '../Calculator';
import {KeyedFixedPaymentOption} from '../calculator-phase.model';
import FixedPayment from './fixed-payment/FixedPayment';
import PaymentModeSwitch from './payment-mode-switch/PaymentModeSwitch';
import styles from './Payment.module.scss';

interface Props {
  disabled?: boolean;
  fixedPaymentsState: {
    fixedPaymentOptions: KeyedFixedPaymentOption[];
    setFixedPaymentOptions?: (options: KeyedFixedPaymentOption[]) => void;
  };
  paymentModeState: {
    isPaymentDynamic: boolean;
    setIsPaymentDynamic?: (isDynamic: boolean) => void;
  };
  paymentIntervalOptionsProps: PaymentIntervalOptionsProps;
  paymentIntervalField?: Field;
}

export type PaymentFieldProps = Props;

const TOTAL_AMORTIZATION_FIELD = `totalAmortizationNumber.${DEFAULT_VALUE}`;
const PAYMENT_INTERVAL_FIELD = `paymentInterval.${DEFAULT_VALUE}`;

export default function Payment(props: Props): ReactElement {

  const {paymentModeState, paymentIntervalField, paymentIntervalOptionsProps, fixedPaymentsState, disabled} = props;
  const {isPaymentDynamic} = paymentModeState;
  const {paymentIntervalOptions, getIsSinglePaymentInterval} = paymentIntervalOptionsProps;
  const isSinglePaymentInterval = getIsSinglePaymentInterval(paymentIntervalField?.[DEFAULT_VALUE]);

  const [totalAmortizationField, , totalAmortizationHelpers] = useField(TOTAL_AMORTIZATION_FIELD);

  useEffect(() => {
    if (isSinglePaymentInterval && totalAmortizationField.value !== 1) {
      totalAmortizationHelpers.setValue(1);
    }
  }, [isSinglePaymentInterval, totalAmortizationField.value]);

  const DynamicPayment = (
    <>
      <NxFormikSelect name={PAYMENT_INTERVAL_FIELD}
                      className={styles.input}
                      label={<PrefixTrans>PAYMENT_INTERVAL</PrefixTrans>}
                      options={paymentIntervalOptions ?? []}
                      disabled={disabled} />
      <NxFormikNumberInput name={TOTAL_AMORTIZATION_FIELD}
                           className={styles.input}
                           label={<PrefixTrans>TOTAL_AMORTIZATION_NUMBER</PrefixTrans>}
                           disabled={disabled || isSinglePaymentInterval}
                           min={0} />
    </>
  );

  return (
    <FormColumn header={<PrefixTrans>PAYMENT</PrefixTrans>}>
      <PaymentModeSwitch paymentModeState={paymentModeState} />
      {
        isPaymentDynamic
          ? DynamicPayment
          : <FixedPayment paymentIntervalOptionsProps={paymentIntervalOptionsProps}
                          fixedPaymentsState={fixedPaymentsState}
                          disabled={disabled} />
      }
    </FormColumn>
  );
}
