import {NxButton, NxFormikInput} from '@nextbank/ui-components';
import {Form, Formik, FormikHelpers, FormikProps} from 'formik';
import React, {ReactElement, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {BATCH} from '../../../constants/api-urls';
import {MAXIMUM_BATCH_FILE_SIZE, MAXIMUM_IMAGE_FILE_SIZE} from '../../../constants/file-uploads';
import useFileUpload from '../../../shared/hooks/use-file-upload.hook';
import usePost from '../../../shared/hooks/use-post.hook';
import HttpService from '../../../shared/http-service/http.service';
import {NxFile} from '../../../shared/model/nx-file.model';
import {TransHelper} from '../../../utils/trans-helper';
import {AppSnackbarContext} from '../../shared/app-snackbar-provider/AppSnackbarProvider';
import {FileTypes} from '../../shared/inputs/nx-file-upload/file-upload.model';
import NxFileUpload from '../../shared/inputs/nx-file-upload/NxFileUpload';
import {BatchTO, CreateBatchPayload} from '../batch.model';
import {CreateBatchErrors, getCreateBatchSchema} from './create-batch-validation-schema';
import {ChangeBatchTypeForm} from './ChangeBatchTypeForm';
import styles from './CreateBatch.module.scss';

export const TRANS_PREFIX = 'BATCH';
export const CREATE_TRANS_PREFIX = `${TRANS_PREFIX}.CREATE`;
export const PrefixTrans = TransHelper.getPrefixedTrans(CREATE_TRANS_PREFIX);

interface CreateBatchForm {
  name?: string;
  file?: NxFile;
  code?: string;
}

interface Props {
  onCreated: () => Promise<void>;
}

const CreateBatch = ({onCreated}: Props): React.ReactElement => {

  const {showSuccessMessage, showErrorMessage} = useContext(AppSnackbarContext);
  const {t} = useTranslation();
  const {uploadFile} = useFileUpload();
  const createBatch = usePost<BatchTO, CreateBatchPayload>(BATCH);

  const handleBatchUpload = (values: CreateBatchForm, {resetForm}: FormikHelpers<CreateBatchForm>): Promise<void | BatchTO> => {
    if (!values.file || !values.file.file || !values.name || !values.code) {
      throw Error('File, name and type have to be present');
    }

    let uploadJobPromise: Promise<void>;
    return uploadFile({file: values.file.file, publiclyAccessible: false})
      .then(fileId => {
        if (!fileId) {
          throw Error('File was not uploaded correctly');
        }

        // Not null check above. eslint cannot see this
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return createBatch({name: values.name!, fileId, code: values.code!});
      })
      .then(batchTO => {
        resetForm();
        // refreshes state after batch creation
        onCreated();
        uploadJobPromise = HttpService.post(`${BATCH}/${batchTO.id}/upload/run`);
        // refreshes state after batch run uploading items
        return onCreated();
      }).then(() => {
        return uploadJobPromise;
      }).then(() => {
        showSuccessMessage(t(`${CREATE_TRANS_PREFIX}.SUCCESS`, {batchName: values.name}));
        return onCreated();
      })
      .catch(error => {
        console.warn(error);
        showErrorMessage(t(`${CREATE_TRANS_PREFIX}.ERROR`, {batchName: values.name}));
      });
  };

  const createBatchForm = ({
                             isSubmitting,
                             errors,
                             setFieldValue,
                             values
                           }: FormikProps<CreateBatchForm>): ReactElement => {

    const handleFileChange = (file?: File): Promise<void> | void => {
      setFieldValue('file', {file: file, isChanged: true} as NxFile);
    };

    return (
      <Form>
        <div className={styles.description}>
          <PrefixTrans>TITLE</PrefixTrans>
        </div>
        <div className={styles.inputs}>
          <NxFormikInput name={'name'}
                         label={<PrefixTrans>BATCH_NAME</PrefixTrans>}
                         className={styles.textInput}
                         positionAbsoluteError={false} />
          <ChangeBatchTypeForm setFieldValue={setFieldValue} />
          <NxFileUpload acceptedFileTypes={[FileTypes.CSV]}
                        maximumFileSize={MAXIMUM_BATCH_FILE_SIZE}
                        className={styles.batchFileUpload}
                        onChange={handleFileChange}
                        value={values.file?.file}
                        externalError={(errors as CreateBatchErrors).file?.file} />
          <NxButton type='submit' disabled={isSubmitting} loaded={!isSubmitting}>
            <PrefixTrans>CREATE</PrefixTrans>
          </NxButton>
        </div>
      </Form>
    );
  };

  return (
    <Formik<CreateBatchForm>
      initialValues={{}}
      onSubmit={handleBatchUpload}
      validationSchema={getCreateBatchSchema(t)}>
      {createBatchForm}
    </Formik>
  );
};

export default CreateBatch;
