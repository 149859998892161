import {TFunction} from 'i18next';
import mapValues from 'lodash/mapValues';
import {AnySchema, boolean, number, object, date} from 'yup';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween'
import {ConfiguredField, Field} from '../../../../../shared/model/field.model';
import {LoanConfiguration} from '../../../../../shared/model/loan-configuration.model';
import {
  getConfiguredFieldValidationDefaultSchema,
  getConfiguredFieldValidationSchema
} from '../../../../../utils/step-form-utils/validation-schema/configured-field-validation-schema';
import {
  getOptionalValidationSchema,
  getTermTooBigError,
  getTermTooSmallError,
  getTooBigError,
  getTooSmallError,
  getDateNotInBetweenRangeError
} from '../../../../../utils/step-form-utils/validation-schema/validation-schema-utils';
import {SIMULATION_REQUIRED_INDICATOR_FIELD} from '../loan-application-data/loan-application-data-form.model';
import {CalculatorFormFields} from './calculator.model';
import {addDaysToDate} from '../../../../../utils/date-helper';
dayjs.extend(isBetween);

export const getValidationSchema = (
  formFields: CalculatorFormFields,
  loanConfig: LoanConfiguration,
  t: TFunction,
  minFirstPaymentDate: string
): AnySchema => {

  const {isPaymentDynamic, ...configuredFields} = formFields; // eslint-disable-line @typescript-eslint/no-unused-vars

  // TODO isnt it always required??
  const simulationSchema =
    getOptionalValidationSchema(partialValidation =>
      partialValidation
        ? object()
        : object().when(SIMULATION_REQUIRED_INDICATOR_FIELD, {is: true, then: object().required()}));

  const simulationParamsChangedSchema =
    getOptionalValidationSchema(partialValidation => partialValidation ? boolean() : boolean().isFalse());

  // TODO LOS-595 Common shape of LoanApplicationData and Calculator validation schema
  const getSimulationInputFieldsSchema = (field: ConfiguredField<unknown>): AnySchema => {

    if (field.code === 'TOTAL_AMORTIZATION_NUMBER') {
      const dedicatedSchema = number()
        .nullable()
        .when('$term', ($term, schema) => {
          return schema.test('Check term high range', getTermTooBigError(t, loanConfig.maxTerm, $term), () => {
              return loanConfig.maxTerm >= $term;
            })
            .test('Check term low range', getTermTooSmallError(t, loanConfig.minTerm, $term), () => {
              return loanConfig.minTerm <= $term;
            });
        });

      return getConfiguredFieldValidationSchema(
        false,
        field as Field<string | number>,
        t,
        dedicatedSchema
      );
    }

    if (field.code === 'DIMINISHING_AMORTIZATION_NUMBER') {
      const dedicatedSchema = number()
        .nullable()
        .min(1, getTooSmallError(t, 1))
        .when('$totalAmortizationNumberValue', ($totalAmortizationNumberValue, schema) => {
          return schema.max($totalAmortizationNumberValue, getTooBigError(t, $totalAmortizationNumberValue));
        });

      return getConfiguredFieldValidationSchema(
        false,
        field as Field<string | number>,
        t,
        dedicatedSchema
      );
    }

    if (field.code === 'PRINCIPAL') {
      const minAmount = loanConfig.minAmount;
      const maxAmount = loanConfig.maxAmount;
      const dedicatedSchema = number()
        .nullable()
        .min(minAmount, getTooSmallError(t, minAmount))
        .max(maxAmount, getTooBigError(t, maxAmount));

      return getConfiguredFieldValidationSchema(
        false,
        field as Field<string | number>,
        t,
        dedicatedSchema
      );
    }

    if (field.code === 'INTEREST_RATE') {
      const minInterestRate = loanConfig.minInterestRate;
      const maxInterestRate = loanConfig.maxInterestRate;
      const dedicatedSchema = number()
        .nullable()
        .min(minInterestRate, getTooSmallError(t, minInterestRate))
        .max(maxInterestRate, getTooBigError(t, maxInterestRate));

      return getConfiguredFieldValidationSchema(
        false,
        field as Field<string | number>,
        t,
        dedicatedSchema
      );
    }

    if (field.code === 'FIRST_PAYMENT_DATE') {
      const maxFirstPaymentDate = addDaysToDate(minFirstPaymentDate, loanConfig.maxFirstPaymentPostponement);
      const dedicatedSchema = date()
        .nullable()
        .when('$firstPaymentDate', ($firstPaymentDate, schema) => {
          if ($firstPaymentDate.value) {
            const isWithinDateRange = dayjs($firstPaymentDate?.value).isBetween(minFirstPaymentDate, maxFirstPaymentDate, 'day', '[]');
            return schema.test(
              'Check first payment date within date range',
              getDateNotInBetweenRangeError(t, minFirstPaymentDate, maxFirstPaymentDate),
              () => isWithinDateRange
            );
          }
        });

      return getConfiguredFieldValidationSchema(
        false,
        field as Field<string | number>,
        t,
        dedicatedSchema
      );
    }

    return getConfiguredFieldValidationDefaultSchema(false, field as Field<string | number>, t);
  };

  return object({
    isPaymentDynamic: boolean(),
    simulation: simulationSchema,
    simulationRequired: boolean(),
    simulationParamsChanged: simulationParamsChangedSchema,
    ...mapValues(configuredFields, field => getSimulationInputFieldsSchema(field as Field<string | number>)
    ),
    term: number().nullable(),
  });
};
