import React, {ReactElement} from 'react';
import {TransHelper} from '../../../../../../utils/trans-helper';
import styles from './SimulationParmeterHint.module.scss';

const COMMON_TRANS_PATH = 'COMMON';
const CommonTrans = TransHelper.getPrefixedTrans(COMMON_TRANS_PATH);
const HintsTrans = TransHelper.getPrefixedTrans('LOAN_APPLICATIONS.SIMULATION_HINTS');

interface Props {
  fieldName: string;
  min: React.ReactNode;
  max: React.ReactNode;
}

const SimulationParameterHint = ({fieldName, min, max}: Props): ReactElement =>
  (
    <div>
      <div><HintsTrans>{fieldName}</HintsTrans></div>
      <div><span className={styles.marginRight}><CommonTrans>MIN</CommonTrans></span>{min}</div>
      <div><span className={styles.marginRight}><CommonTrans>MAX</CommonTrans></span>{max}</div>
    </div>
  );

export default SimulationParameterHint;
