import {NxLoader} from '@nextbank/ui-components';
import {Formik, yupToFormErrors} from 'formik';
import {FormikErrors} from 'formik/dist/types';
import {pickBy} from 'lodash';
import isNil from 'lodash/isNil';
import mapValues from 'lodash/mapValues';
import React, {ReactElement, useContext, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {LOAN_SIMULATION_URL, PhaseName} from '../../../../../constants/api-urls';
import {CALENDAR_INPUT_DATE_FORMAT} from '../../../../../constants/format-templates';
import {UrlParams} from '../../../../../routes/routes.model';
import useCalculatorDictionaryEntries from '../../../../../shared/hooks/use-calculator-dictionary-entries.hook';
import useLoanApplicationDataOptions from '../../../../../shared/hooks/use-loan-application-data-options.hook';
import usePost from '../../../../../shared/hooks/use-post.hook';
import useGet from '../../../../../shared/hooks/use-get.hook';
import useSingleQuery from '../../../../../shared/hooks/use-single-query.hook';
import {LoanSimulation, LoanSimulationParams} from '../../../../../shared/model/loan-simulation.model';
import {PaymentType} from '../../../../../shared/model/payment.model';
import {ApiHelper} from '../../../../../utils/api-helper';
import {optionalValidationParams} from '../../../../../utils/optional-validation-form-utils';
import {
  getSimulationDataBasedOnFields,
  updateConfiguredFieldsWithSavedData,
  overrideInitialValuesWithExternalApplicationData
} from '../../../../../utils/step-form-utils/calculator-fields-utils';
import {
  ConfiguredFieldRecords,
  FieldRecords,
  getConfiguredFields
} from '../../../../../utils/step-form-utils/configured-fields-utils';
import {getLoanApplicationDataInitFields} from '../../../../../utils/step-form-utils/loan-application-data-init-fields';
import {TransHelper} from '../../../../../utils/trans-helper';
import {
  LoanApplicationDataPhase
} from '../../../../loan-configurations/loan-configuration/steps/loan-application-data/loan-application-data-phase.model';
import {AppSnackbarContext} from '../../../../shared/app-snackbar-provider/AppSnackbarProvider';
import {SystemContext} from '../../../../shared/system-context-provider/SystemContextProvider';
import {LoanApplicationContext} from '../../LoanApplication';
import {StepPayloadType} from '../shared/step-payload.model';
import {LoanApplicationDataFormFields, LoanApplicationDataPayload} from './loan-application-data-form.model';
import {getValidationSchema} from './loan-application-data-validation-schema';
import {LoanApplicationDataForm} from './LoanApplicationDataForm';
import {getUrlParams} from '../shared/simulation/loan-parameters/use-calculate-term';
import {CALCULATE_TERM_URL} from '../../../../../constants/api-urls';
import {ExternalApplicationData} from '../../../../../shared/model/external-application.model';

const {getPhaseConfigurationUrl, getPhaseSaveUrl, getExternalApplicationUrl} = ApiHelper;
export const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_CONFIGURATIONS.LOAN_APPLICATION_DATA');

export default function LoanApplicationData(): React.ReactElement {

  const {t} = useTranslation();
  const {applicationId} = useParams<UrlParams>();
  const {process, processId, application, setOpenedPhaseId} = useContext(LoanApplicationContext);
  const {webServerTime} = useContext(SystemContext);
  const {showErrorMessage} = useContext(AppSnackbarContext);
  const phaseUrl = getPhaseConfigurationUrl(processId, PhaseName.LOAN_APPLICATION_DATA);
  const getCalculateTerm = useGet<string>(CALCULATE_TERM_URL);
  const config = useSingleQuery<LoanApplicationDataPhase>(phaseUrl);
  const saveStep = usePost<void, LoanApplicationDataPayload>(getPhaseSaveUrl(applicationId));
  const runSimulation = usePost<LoanSimulation[], LoanSimulationParams>(LOAN_SIMULATION_URL);
  const fetchExternalApplication = useGet<ExternalApplicationData>(getExternalApplicationUrl(applicationId));
  const {
    areOptionsLoaded,
    accountInformationOptions,
    loanParametersOptions
  } = useLoanApplicationDataOptions(process.loanProduct, config);
  const {dictionaryEntries, areDictionariesLoaded} = useCalculatorDictionaryEntries(config);
  const [minFirstPaymentDate, setMinFirstPaymentDate] = useState('');
  const [externalApplicationData, setExternalApplicationData] = useState<ExternalApplicationData>();
  const [loanTerm, setLoanTerm] = useState<string>();

  useEffect(() => {
    (async (): Promise<void> => {
      if (config !== undefined) {
        setOpenedPhaseId(config.id);
        const initialValues = getFormInitialValues(config);
        // Adds term value to initial values for loan application data
        if (!isNil(initialValues.totalAmortizationNumber?.value) && initialValues.paymentInterval?.value && dictionaryEntries.paymentInterval) {
          const urlSearchParams = getUrlParams(
            initialValues.paymentInterval.value as string,
            initialValues.totalAmortizationNumber.value,
            dictionaryEntries.paymentInterval
          );
          const term = await getCalculateTerm(urlSearchParams)
          setLoanTerm(term);
        }
      }
    })();
  }, [config, dictionaryEntries]);

  const submit = async (values: LoanApplicationDataFormFields): Promise<void> => {
    if (config?.id !== application?.currentPhaseId) {
      throw new Error('Unable to save due to not on current phase');
    }

    const {
      loanClassId, loanPurposeId, loanSecurityId, loanEconomicActivityId, microfinanceClassificationId, misGroupId,
      borrowerTypeId, transactionTypeId, cicContractTypeId, loanPurposeToIndustryId
    } = mapValues(values, 'value');

    if (values.simulation === undefined) {
      throw new Error('Simulation must be defined on submit step');
    }

    const stepPayload: LoanApplicationDataPayload = {
      type: StepPayloadType.LOAN_APPLICATION_DATA_PAYLOAD,
      loanParameters: {...values.simulation},
      loanClassId,
      loanPurposeId,
      loanSecurityId,
      loanEconomicActivityId,
      microfinanceClassificationId,
      borrowerTypeId,
      transactionTypeId,
      cicContractTypeId,
      loanPurposeToIndustryId,
      misGroupId
    };

    return saveStep(stepPayload, null, optionalValidationParams(values.validate));
  };

  const getFormInitialValues = (config: LoanApplicationDataPhase): LoanApplicationDataFormFields => {

    const {maxCoMakerNumber, minCoMakerNumber, maxCoBorrowerNumber, minCoBorrowerNumber, ...configuredFields}
      = getLoanApplicationDataInitFields(config);

    // Removes all undefined fields to make it parsable
    const cleanedFieldsConfig = pickBy(configuredFields);
    const configuredFieldsInitData = {
      ...getConfiguredFields(cleanedFieldsConfig as FieldRecords)
    } as ConfiguredFieldRecords;

    const simulation = application?.simulations.find(simulation => simulation.phaseId === config.id);
    const simulationInput = simulation?.input;
    const applicationData = application?.applicationData;

    if (simulationInput || applicationData) {
      // Override configuration default values, if simulation has been already saved

      const simulationData = {
        ...simulationInput,
        principal: simulationInput?.principalAmount,
        dateGranted: simulationInput?.grantDate,
        ...applicationData
      };

      updateConfiguredFieldsWithSavedData(configuredFieldsInitData, dictionaryEntries, simulationData);
    }

    const isPaymentDynamic = config.paymentType === PaymentType.DYNAMIC;

    const initialValues = {
      ...configuredFieldsInitData,
      isPaymentDynamic,
      maxCoMakerNumber,
      minCoMakerNumber,
      maxCoBorrowerNumber,
      minCoBorrowerNumber,
      simulation,
      ...(loanTerm !== undefined && { term: loanTerm })
    } as unknown as LoanApplicationDataFormFields;

    if (isNil(simulationInput)) {
      overrideInitialValuesWithExternalApplicationData(
        externalApplicationData,
        initialValues,
        dictionaryEntries.paymentInterval,
        process,
        loanParametersOptions.paymentIntervalOptionsProps,
      );
    }

    return initialValues;
  };

  useEffect(() => {
    fetchExternalApplication().then(externalApplicationData => {
      setExternalApplicationData(externalApplicationData);
    })
  }, []);

  const getFormik = (config: LoanApplicationDataPhase): ReactElement => {

    const initialValues = getFormInitialValues(config);
    initialValues.dateGranted.value = initialValues.dateGranted.value ?? webServerTime.format(CALENDAR_INPUT_DATE_FORMAT);

    const getSimulationDataValues = (values: LoanApplicationDataFormFields): LoanSimulationParams =>
      getSimulationDataBasedOnFields(values, dictionaryEntries, processId);
    const emptyErrors: FormikErrors<LoanApplicationDataFormFields> = {};

    return (
      <Formik<LoanApplicationDataFormFields>
        onSubmit={submit}
        validateOnChange={false}
        validate={async (values): Promise<FormikErrors<LoanApplicationDataFormFields> | void> => {
          return await getValidationSchema(initialValues, process.loanProduct, t, minFirstPaymentDate)
            .validate(values, {
              abortEarly: false, context: {
                totalAmortizationNumberValue: values.totalAmortizationNumber.value,
                term: values.term,
                firstPaymentDate: values.firstPaymentDate
              }
            })
            .then(() => emptyErrors)
            .catch(errors => yupToFormErrors(errors) as FormikErrors<LoanApplicationDataFormFields>);
        }}
        initialValues={initialValues}>
        {
          (props): ReactElement =>
            <LoanApplicationDataForm formikProps={props}
                                     config={config}
                                     runSimulation={runSimulation}
                                     showErrorMessage={showErrorMessage}
                                     getSimulationDataValues={getSimulationDataValues}
                                     options={{loanParametersOptions, accountInformationOptions}}
                                     paymentIntervalEntries={dictionaryEntries.paymentInterval}
                                     setMinFirstPaymentDate={setMinFirstPaymentDate} />
        }
      </Formik>
    );
  };

  return isNil(config) || !areOptionsLoaded && !areDictionariesLoaded ? <NxLoader /> : getFormik(config);
}
