import {NxSelectOption} from '@nextbank/ui-components';
import mapValues from 'lodash/mapValues';
import values from 'lodash/values';
import {useMemo} from 'react';
import {
  LoanApplicationDataPhase
} from '../../components/loan-configurations/loan-configuration/steps/loan-application-data/loan-application-data-phase.model';
import {DictionaryHelper} from '../../utils/dictionary-helper';
import {areNotNil} from '../../utils/empty-checks-utils';
import {SelectHelper} from '../../utils/select-helper';
import {StaticFieldsHelper} from '../../utils/step-form-utils/static-fields-helper';
import {LoanConfiguration} from '../model/loan-configuration.model';
import {LOAN_APPLICATION_FIELD_CODES as FIELD_CODES} from '../model/step-forms/loan-application-data-form.model';
import useDictionariesQuery from './use-dictionaries-query.hook';
import usePaymentIntervalOptions, {PaymentIntervalOptionsProps} from './use-payment-interval-options.hook';

const {mapDictionaryToOptions} = SelectHelper;

export interface LoanParametersOptions {
  advanceInterestApplication?: NxSelectOption[];
  creationType?: NxSelectOption[];
  paymentIntervalOptionsProps: PaymentIntervalOptionsProps;
  uidApplicationOptions?: NxSelectOption[];
}

export interface AccountInformationOptions {
  loanClassId?: NxSelectOption[];
  loanPurposeId?: NxSelectOption[];
  loanSecurityId?: NxSelectOption[];
  loanEconomicActivityId?: NxSelectOption[];
  microfinanceClassificationId?: NxSelectOption[];
  borrowerTypeId?: NxSelectOption[];
  transactionTypeId?: NxSelectOption[];
  cicContractTypeId?: NxSelectOption[];
  loanPurposeToIndustryId?: NxSelectOption[];
  misGroupId?: NxSelectOption[];
}

export interface LoanApplicationDataOptions {
  areOptionsLoaded?: boolean;
  accountInformationOptions: AccountInformationOptions;
  loanParametersOptions: LoanParametersOptions;
}

export interface LoanApplicationDictionaryIds {
  advanceInterestApplication?: number;
  creationType?: number;
  paymentInterval?: number;
  loanClassId?: number;
  loanPurposeId?: number;
  loanSecurityId?: number;
  loanEconomicActivityId?: number;
  microfinanceClassificationId?: number;
  borrowerTypeId?: number;
  transactionTypeId?: number;
  cicContractTypeId?: number;
  loanPurposeToIndustryId?: number;
  misGroupId?: number;
  uidApplication?: number;
}

const getDictionaryIds = (config: LoanApplicationDataPhase): LoanApplicationDictionaryIds => {
  const dictionaryIds = mapValues({
    advanceInterestApplication: undefined,
    creationType: undefined,
    paymentInterval: undefined,
    loanClassId: undefined,
    loanPurposeId: undefined,
    loanSecurityId: undefined,
    loanEconomicActivityId: undefined,
    microfinanceClassificationId: undefined,
    borrowerTypeId: undefined,
    transactionTypeId: undefined,
    cicContractTypeId: undefined,
    loanPurposeToIndustryId: undefined,
    misGroupId: undefined
  }, (id, key) => StaticFieldsHelper.getFieldDictionaryId(config, FIELD_CODES[key]));

  return {
    ...dictionaryIds,
    uidApplication: StaticFieldsHelper.getOptionalFieldDictionaryId(config, 'UID_APPLICATION')
  };
};

export default function useLoanApplicationDataOptions(loanProduct: LoanConfiguration,
                                                      config?: LoanApplicationDataPhase): LoanApplicationDataOptions {

  const dictionaryIds: LoanApplicationDictionaryIds = useMemo(
    () => config ? getDictionaryIds(config) : {},
    [config]
  );

  const {dictionaries, loaded} = useDictionariesQuery<LoanApplicationDictionaryIds>(dictionaryIds);

  const {
    paymentInterval, loanClassId, loanPurposeId, loanSecurityId, loanEconomicActivityId, loanPurposeToIndustryId,
    microfinanceClassificationId, borrowerTypeId, transactionTypeId, cicContractTypeId, misGroupId
  } = dictionaryIds;

  const accountInformationOptions = mapValues({
    borrowerTypeId,
    loanClassId,
    loanPurposeId,
    loanSecurityId,
    loanEconomicActivityId,
    microfinanceClassificationId,
    transactionTypeId,
    cicContractTypeId,
    loanPurposeToIndustryId,
    misGroupId
  }, id => mapDictionaryToOptions(dictionaries, id));

  const paymentIntervalEntries = DictionaryHelper.getEntries(dictionaries, paymentInterval);
  const advanceInterestApplication = mapDictionaryToOptions(dictionaries, dictionaryIds.advanceInterestApplication);
  const creationType = mapDictionaryToOptions(dictionaries, dictionaryIds.creationType);
  const paymentIntervalOptionsProps = usePaymentIntervalOptions(loanProduct, paymentIntervalEntries);
  const uidApplicationOptions = mapDictionaryToOptions(dictionaries, dictionaryIds?.uidApplication);

  const areOptionsLoaded = loaded && areNotNil(values(accountInformationOptions))
    && areNotNil([advanceInterestApplication, creationType, paymentIntervalOptionsProps.paymentIntervalOptions]);

  return {
    areOptionsLoaded,
    accountInformationOptions,
    loanParametersOptions: {
      advanceInterestApplication,
      creationType,
      paymentIntervalOptionsProps,
      uidApplicationOptions
    }
  };
}

