import React, {useState, useEffect} from 'react';
import {NxButton, NxButtonVariant} from '@nextbank/ui-components';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {isEmpty} from 'lodash';
import IconButtonSwitch from '../../icon-button-switches/IconButtonSwitch';
import {ReactComponent as ViewIcon} from '../../../../assets/images/icon-view.svg'
import styles from './PdfPreviewDialog.module.scss';

interface PdfContentDialogProps {
  close: () => void;
  isVisible: boolean;
  pdfFile: File | undefined;
}

const PdfContentDialog = ({close, isVisible, pdfFile} : PdfContentDialogProps) => {
  const [pdfUrl, setPdfUrl] = useState<string>('');

  const closeDialog = () : void => {
    close();
    URL.revokeObjectURL(pdfUrl);
  };

  useEffect(() => {
    if (pdfFile !== undefined && isVisible) {
      let url;
      if (isEmpty(pdfFile.type)) {
        const updatedBlob = new Blob([pdfFile], { type: 'application/pdf' });
        const updatedFile = new File([updatedBlob], pdfFile.name, {
          type: 'application/pdf',
          lastModified: pdfFile.lastModified
        });
        url = URL.createObjectURL(updatedFile);
      } else {
        url = URL.createObjectURL(pdfFile);
      }
      setPdfUrl(url);
    }
  }, [pdfFile, isVisible]);

  return (
    <Dialog maxWidth={'xl'} open={isVisible} onClose={closeDialog}>
        <DialogContent className={styles.dialogContent}>
          <div className={styles.previewContentContainer}>
            <iframe className={styles.pdfContent} src={pdfUrl}/>
          </div>
        </DialogContent>
        <DialogActions>
          <NxButton variant={NxButtonVariant.CLOSE} onClick={closeDialog}>
            Close
          </NxButton>
        </DialogActions>
      </Dialog>
  );
}

interface PdfViewDialogProps {
  pdfFile: File | undefined;
}

const PdfViewDialog = ({pdfFile} : PdfViewDialogProps) => {
  const [visible, setVisible] = useState(false);

  const viewPdf = () : void => {
    setVisible(true);
  };

  const handleClose = () : void => {
    setVisible(false);
  };

  return (
    <div>
      <IconButtonSwitch className={styles.viewPdf}
                        ariaLabel={'view pdf file'}
                        onClick={viewPdf}
                        icon={<ViewIcon />}
                        bordered={false} />
      <PdfContentDialog isVisible={visible} close={handleClose} pdfFile={pdfFile} />
    </div>
  );
}

export default PdfViewDialog;