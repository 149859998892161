import {NxLoader} from '@nextbank/ui-components';
import {Formik, FormikProps, yupToFormErrors} from 'formik';
import {FormikErrors} from 'formik/dist/types';
import {pickBy} from 'lodash';
import isNil from 'lodash/isNil';
import isNaN from 'lodash/isNaN';
import React, {ReactElement, useContext, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {PhaseName} from '../../../../../constants/api-urls';
import {CALENDAR_INPUT_DATE_FORMAT} from '../../../../../constants/format-templates';
import {UrlParams} from '../../../../../routes/routes.model';
import useCalculatorDictionaryEntries from '../../../../../shared/hooks/use-calculator-dictionary-entries.hook';
import usePaymentIntervalOptions from '../../../../../shared/hooks/use-payment-interval-options.hook';
import usePost from '../../../../../shared/hooks/use-post.hook';
import useSingleQuery from '../../../../../shared/hooks/use-single-query.hook';
import {LoanSimulationParams} from '../../../../../shared/model/loan-simulation.model';
import {PaymentType} from '../../../../../shared/model/payment.model';
import {ApiHelper} from '../../../../../utils/api-helper';
import {optionalValidationParams} from '../../../../../utils/optional-validation-form-utils';
import {SelectHelper} from '../../../../../utils/select-helper';
import {
  getSimulationDataBasedOnFields,
  updateConfiguredFieldsWithSavedData,
  overrideInitialValuesWithExternalApplicationData
} from '../../../../../utils/step-form-utils/calculator-fields-utils';
import {getCalculatorInitFields} from '../../../../../utils/step-form-utils/calculator-init-fields';
import {
  ConfiguredFieldRecords,
  FieldRecords,
  getConfiguredFields
} from '../../../../../utils/step-form-utils/configured-fields-utils';
import {TransHelper} from '../../../../../utils/trans-helper';
import {
  CalculatorPhase
} from '../../../../loan-configurations/loan-configuration/steps/calculator/calculator-phase.model';
import {SystemContext} from '../../../../shared/system-context-provider/SystemContextProvider';
import {LoanApplicationSimulation} from '../../loan-application.model';
import {LoanApplicationContext} from '../../LoanApplication';
import {StepPayloadType} from '../shared/step-payload.model';
import {getValidationSchema} from './calculator-validation-schema';
import {CalculatorFormFields, CalculatorPayload} from './calculator.model';
import {CalculatorForm} from './CalculatorForm';
import {ChangeProductForm} from './ChangeProductForm';
import StartApplicationButton from './start-application-button/StartApplicationButton';
import useGet from '../../../../../shared/hooks/use-get.hook';
import {ExternalApplicationData} from '../../../../../shared/model/external-application.model';

export const CalculatorTrans = TransHelper.getPrefixedTrans('LOAN_APPLICATIONS.CALCULATOR');
export const FieldTrans = TransHelper.getPrefixedTrans('COMMON.FIELDS');
const {getPhaseConfigurationUrl, getPhaseSaveUrl, getExternalApplicationUrl} = ApiHelper;
const {mapToStringOptions} = SelectHelper;

export default function Calculator(): React.ReactElement {

  const {t} = useTranslation();
  const {process, application, setProcessChanged, setOpenedPhaseId} = useContext(LoanApplicationContext);
  const {webServerTime} = useContext(SystemContext);
  const [calculatedFirstPaymentDate, setCalculatedFirstPaymentDate] = useState('');
  const config = useSingleQuery<CalculatorPhase>(getPhaseConfigurationUrl(process.id, PhaseName.CALCULATOR));

  //May be null
  const {applicationId} = useParams<UrlParams>();
  const saveStep = usePost<void, CalculatorPayload>(getPhaseSaveUrl(applicationId));
  const fetchExternalApplication = useGet<ExternalApplicationData>(getExternalApplicationUrl(applicationId));

  // Extract??
  const {dictionaryEntries, areDictionariesLoaded} = useCalculatorDictionaryEntries(config);
  const advanceInterestApplicationOptions = mapToStringOptions(dictionaryEntries.advanceInterestApplication);
  const creationTypeOptions = mapToStringOptions(dictionaryEntries.creationType);
  const paymentIntervalOptionsProps = usePaymentIntervalOptions(process.loanProduct, dictionaryEntries.paymentInterval);
  const uidApplicationOptions = mapToStringOptions(dictionaryEntries.uidApplication);
  const [externalApplicationData, setExternalApplicationData] = useState<ExternalApplicationData>();

  const getFormInitialValues = (config: CalculatorPhase): CalculatorFormFields => {
    const fieldsConfig = {
      ...getCalculatorInitFields(config)
    };

    // Removes all undefined fields to make it parsable
    const cleanedFieldsConfig = pickBy(fieldsConfig);
    const calculatorFormFields = {
      ...getConfiguredFields(cleanedFieldsConfig as FieldRecords)
    } as unknown as CalculatorFormFields;

    const isPaymentDynamic = config.paymentType === PaymentType.DYNAMIC;

    const initialValues = {...calculatorFormFields, isPaymentDynamic};

    if (application) {

      // Override configuration default values, if simulation has been already started
      const simulation = application?.simulations.find(simulation => simulation.phaseId === config.id) as LoanApplicationSimulation;

      if (isNil(simulation)) {
        overrideInitialValuesWithExternalApplicationData(
          externalApplicationData,
          initialValues,
          dictionaryEntries.paymentInterval,
          process,
          paymentIntervalOptionsProps
        );
        return initialValues;
      }

      const simulationInput = simulation?.input;
      const savedData = {
        ...simulationInput,
        principal: simulationInput?.principalAmount,
        dateGranted: simulationInput?.grantDate
      };

      updateConfiguredFieldsWithSavedData(
        calculatorFormFields as unknown as ConfiguredFieldRecords,
        dictionaryEntries,
        savedData
      );

      return {...calculatorFormFields, isPaymentDynamic, simulation};
    }
    return initialValues;
  };

  const submit = async (values: CalculatorFormFields): Promise<void> => {
    if (values.simulation === undefined) {
      throw new Error('Simulation must be defined on submit step');
    }

    const stepPayload: CalculatorPayload = {
      type: StepPayloadType.CALCULATOR_PAYLOAD,
      loanParameters: {...values.simulation},
      processId: process.id
    };

    return saveStep(stepPayload, null, optionalValidationParams(true))
      .finally(() =>
        setProcessChanged(false)
      );
  };

  const getSimulationDataValues = (values: CalculatorFormFields): LoanSimulationParams =>
    getSimulationDataBasedOnFields(values, dictionaryEntries, process.id);

  useEffect(() => {
    if (config) {
      setOpenedPhaseId(config.id);
    }
  }, [config]);

  useEffect(() => {
    if (!isNil(applicationId) && !isNaN(Number(applicationId))) {
      fetchExternalApplication().then(externalApplicationData => {
        setExternalApplicationData(externalApplicationData);
      });
    }
  }, [applicationId]);

  // TODO LOS-366 LoanApplicationData and Calculator refactor to common shape
  const getFormik = (config: CalculatorPhase): ReactElement => {

    const initialValues = getFormInitialValues(config);
    initialValues.dateGranted.value = initialValues.dateGranted.value ?? webServerTime.format(CALENDAR_INPUT_DATE_FORMAT);

    const emptyErrors: FormikErrors<CalculatorFormFields> = {};
    return (
      <Formik<CalculatorFormFields>
        onSubmit={submit}
        initialValues={initialValues}
        validate={async (values): Promise<FormikErrors<CalculatorFormFields> | void> => {
          return await getValidationSchema(initialValues, process.loanProduct, t, calculatedFirstPaymentDate)
            .validate(values, {
              abortEarly: false, context: {
                totalAmortizationNumberValue: values.totalAmortizationNumber.value,
                term: values.term,
                firstPaymentDate: values.firstPaymentDate
              }
            })
            .then(() => emptyErrors)
            .catch(errors => {
              console.warn(errors);
              return yupToFormErrors(errors) as FormikErrors<CalculatorFormFields>;
            });
        }}
        validateOnChange={false}
        validateOnBlur={false}>
        {
          (props: FormikProps<CalculatorFormFields>): ReactElement => {
            const stepNavigation = !isNil(application) || isNil(config) ? undefined :
              <StartApplicationButton calculatorFormFields={props.values}
                                      phaseId={config?.id}
                                      dictionaryEntries={dictionaryEntries}
              />;
            return <>
              <ChangeProductForm processId={process.id}
                                 applicationId={applicationId} />
              <CalculatorForm config={config}
                              loanConfig={process.loanProduct}
                              application={application}
                              formikProps={props}
                              getSimulationDataValues={getSimulationDataValues}
                              loanParametersOptions={{
                                advanceInterestApplication: advanceInterestApplicationOptions,
                                creationType: creationTypeOptions,
                                paymentIntervalOptionsProps,
                                uidApplicationOptions
                              }}
                              paymentIntervalEntries={dictionaryEntries.paymentInterval}
                              stepNavigation={stepNavigation}
                              setMinFirstPaymentDate={setCalculatedFirstPaymentDate} />
            </>;
          }
        }
      </Formik>
    );
  };

  return isNil(config) || !areDictionariesLoaded ? <NxLoader /> : getFormik(config);
}
