import mapValues from 'lodash/mapValues';
import {
  CalculatorPhase
} from '../../components/loan-configurations/loan-configuration/steps/calculator/calculator-phase.model';
import {PaymentType} from '../../shared/model/payment.model';
import {CalculatorFormFieldsConfiguration} from '../../shared/model/step-forms/calculator-form.model';
import {StaticFieldsHelper} from './static-fields-helper';

const {getInitField, getInitFormFields} = StaticFieldsHelper;

export const getCalculatorInitFields = (config: CalculatorPhase): CalculatorFormFieldsConfiguration => {

  const fieldsCodes = {
    availableCreditLine: 'AVAILABLE_CREDIT_LINE',
    creationType: 'CREATION_TYPE',
    principal: 'PRINCIPAL',
    interestRate: 'INTEREST_RATE',
    paymentInterval: 'PAYMENT_INTERVAL',
    totalAmortizationNumber: 'TOTAL_AMORTIZATION_NUMBER',
    overrideAmortizationAmount: 'OVERRIDE_AMORTIZATION_AMOUNT',
    diminishingAmortizationNumber: 'DIMINISHING_AMORTIZATION_NUMBER',
    advanceInterestNo: 'ADVANCE_INTEREST_NO',
    advanceInterestApplication: 'ADVANCE_INTEREST_APPLICATION',
    dateGranted: 'DATE_GRANTED',
    firstPaymentDate: 'FIRST_PAYMENT_DATE',
    interestCalculationParameter: 'INTEREST_CALCULATION_PARAMETER',
    uidAmortizationNumber: 'UID_AMORTIZATION_NUMBER',
    uidApplication: 'UID_APPLICATION'
  };

  const numberInputsKeys = [
    'principal',
    'interestRate',
    'advanceInterestNo',
    'totalAmortizationNumber',
    'overrideAmortizationAmount',
    'diminishingAmortizationNumber',
    'interestCalculationParameter',
    'uidAmortizationNumber'
  ];

  const emptyFormFields = mapValues(fieldsCodes, getInitField) as CalculatorFormFieldsConfiguration;
  const initFormFields = getInitFormFields(emptyFormFields, config, numberInputsKeys);

  return {
    ...initFormFields,
    totalAmortizationNumber: {
      ...initFormFields.totalAmortizationNumber,
      defaultValue: config.paymentType == PaymentType.DYNAMIC
        ? initFormFields.totalAmortizationNumber.defaultValue
        : undefined
    }
  };
};
