import {Table, TableBody, TableContainer, TableRow} from '@material-ui/core';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {RoutePaths} from '../../../../routes/routes.paths';
import {Process} from '../../../../shared/model/process.model';
import {CashHelper} from '../../../../utils/cash-helper';
import {buildApplicationStepPath} from '../../../../utils/loan-steps-utils';
import {StringHelper} from '../../../../utils/string-helper';
import {NEW_LOAN_APPLICATION_ID} from '../../../dashboard/routes/dashboard-routes';
import {useGuardedHistory} from '../../../router/GuardedHistory';
import Instruction from '../../../shared/instruction/Instruction';
import TableDataCell from '../../../shared/table/cells/TableDataCell';
import LoanTypeSelectionTableHead from './loan-type-selection-table-head/LoanTypeSelectionTableHead';
import styles from './LoanTypeSelectionTable.module.scss';

interface Props {
  processes: Process[] | undefined;
}

const {addDays, addPercentage} = StringHelper;

const LoanTypeSelectionTable = ({processes}: Props): React.ReactElement => {

  const {t} = useTranslation();
  const history = useGuardedHistory();

  const navigateToNewLoanApplication = (processId: number): () => void => (): void => {
    const newLoanCalculatorPath = buildApplicationStepPath(RoutePaths.CALCULATOR, processId, NEW_LOAN_APPLICATION_ID);
    history.push(newLoanCalculatorPath);
  };

  return (
    <>
      <Instruction>
        <Trans>LOAN_APPLICATIONS.SELECT_APPLICATION</Trans>
      </Instruction>
      <TableContainer>
        <Table>
          <LoanTypeSelectionTableHead />
          <TableBody>
            {
              processes?.map(({id, loanProduct}, index) =>
                <TableRow onClick={navigateToNewLoanApplication(id)}
                          className={styles.tableRow}
                          key={index}>
                  <TableDataCell data={index + 1} />
                  <TableDataCell data={loanProduct.code} />
                  <TableDataCell data={loanProduct.name} />
                  <TableDataCell data={addPercentage(loanProduct.minInterestRate)} />
                  <TableDataCell data={addPercentage(loanProduct.maxInterestRate)} />
                  <TableDataCell data={CashHelper.addCurrencyMask(loanProduct.minAmount)} />
                  <TableDataCell data={CashHelper.addCurrencyMask(loanProduct.maxAmount)} />
                  <TableDataCell data={addDays(loanProduct.minTerm, t)} />
                  <TableDataCell data={addDays(loanProduct.maxTerm, t)} />
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default LoanTypeSelectionTable;
