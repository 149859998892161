import {NxInput, NxSelect} from '@nextbank/ui-components';
import clsx from 'clsx';
import {cloneDeep} from 'lodash';
import React, {ChangeEvent, useContext, useEffect} from 'react';
import {DroppableProvided} from 'react-beautiful-dnd';
import {Trans, useTranslation} from 'react-i18next';
import {Field, FieldType} from '../../../../../../shared/model/field.model';
import {getFieldTypeOptions, isDictionaryType} from '../../../../../../utils/custom-fields-utils';
import {getInputValue} from '../../../../../../utils/input-utils';
import BinButton from '../../../../../shared/bin-button/BinButton';
import CustomDictionarySidePanel from '../../../../../shared/custom-dictionary/CustomDictionarySidePanel';
import DragDropItem from '../../../../../shared/drag-drop/DragDropItem';
import RequiredButtonSwitch from '../../../../../shared/icon-button-switches/RequiredButtonSwitch';
import SelfCareButtonSwitch from '../../../../../shared/icon-button-switches/SelfCareButtonSwitch';
import styles from './CustomField.module.scss';
import {CustomFieldsContext} from './CustomFields';
import {KeyedField} from './keyed-fields.model';

interface Props {
  field: KeyedField;
  groupIndex: number;
  provided: DroppableProvided;
  isDragging: boolean;
}

export default function CustomField({field, groupIndex, provided, isDragging}: Props): React.ReactElement {

  const {t} = useTranslation();
  const {fieldGroups, setFieldGroups, phaseId} = useContext(CustomFieldsContext);

  useEffect((): void => {
    if (getFieldIndex() <= -1) {
      throw new Error('Custom field index out of range.');
    }
  }, [fieldGroups]);

  const getFieldIndex = (): number =>
    fieldGroups[groupIndex].fields.findIndex(fieldItem => fieldItem.key === field.key);

  const updateField = (fieldKey: keyof KeyedField, value: unknown): void => {
    const updatedFieldGroups = cloneDeep(fieldGroups);
    const fieldToUpdate = updatedFieldGroups[groupIndex].fields[getFieldIndex()] as Field;
    fieldToUpdate[fieldKey] = value;
    setFieldGroups(updatedFieldGroups);
  };

  const removeField = (): void => {
    const updatedFieldGroups = cloneDeep(fieldGroups);
    updatedFieldGroups[groupIndex].fields.splice(getFieldIndex(), 1);
    setFieldGroups(updatedFieldGroups);
  };

  const handleNameChange = (event: ChangeEvent): void => updateField('name', getInputValue(event));
  const handleTypeChange = (type: string | null): void => updateField('type', type as FieldType);
  const setFieldSelfCare = (): void => updateField('selfCare', !field.selfCare);
  const setFieldRequired = (): void => updateField('required', !field.required);
  const handleDictionarySave = (dictionaryId: number): void => updateField('dictionaryId', dictionaryId);

  const FieldItem = (
    <>
      <NxInput label={<Trans>COMMON.FIELDS.FIELD_NAME</Trans>}
               className={styles.fieldInput}
               value={field.name}
               onChange={handleNameChange} />
      <NxSelect className={clsx(styles.fieldInput, styles.fieldInput_type)}
                options={getFieldTypeOptions(t, false)}
                label={<Trans>COMMON.FIELDS.TYPE</Trans>}
                onChange={handleTypeChange}
                value={field.type ?? ''} />
      {
        isDictionaryType(field.type) &&
          <CustomDictionarySidePanel handleSave={handleDictionarySave}
                                     dictionaryId={field.dictionaryId}
                                     phaseId={phaseId}/>
      }
      <SelfCareButtonSwitch onClick={setFieldSelfCare} value={field.selfCare} />
      <RequiredButtonSwitch onClick={setFieldRequired} value={field.required} />
      <BinButton className={styles.bin} onClick={removeField} />
    </>
  );

  return <DragDropItem provided={provided} isDragging={isDragging} item={FieldItem} />;
}
