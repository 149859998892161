import mapValues from 'lodash/mapValues';
import values from 'lodash/values';
import {useMemo} from 'react';
import {
  CalculatorPhase
} from '../../components/loan-configurations/loan-configuration/steps/calculator/calculator-phase.model';
import {DictionaryHelper} from '../../utils/dictionary-helper';
import {areNotNil} from '../../utils/empty-checks-utils';
import {StaticFieldsHelper} from '../../utils/step-form-utils/static-fields-helper';
import {DictionaryEntry} from '../model/dictionary.model';
import useDictionariesQuery from './use-dictionaries-query.hook';

export interface CalculatorDictionaryState {
  areDictionariesLoaded?: boolean;
  dictionaryEntries: CalculatorDictionaryEntries;
}

export interface CalculatorDictionaryEntries {
  advanceInterestApplication?: DictionaryEntry[];
  creationType?: DictionaryEntry[];
  paymentInterval?: DictionaryEntry[];
  uidApplication?: DictionaryEntry[];
}

export interface CalculatorDictionaryIds {
  advanceInterestApplication?: number;
  creationType?: number;
  paymentInterval?: number;
  uidApplication?: number;
}

const {getEntries} = DictionaryHelper;

const getDictionaryIds = (config: CalculatorPhase): CalculatorDictionaryIds => {
  const dictionaryIds = mapValues({
    advanceInterestApplication: 'ADVANCE_INTEREST_APPLICATION',
    creationType: 'CREATION_TYPE',
    paymentInterval: 'PAYMENT_INTERVAL'
  }, code => StaticFieldsHelper.getFieldDictionaryId(config, code));

  return {
    ...dictionaryIds,
    uidApplication: StaticFieldsHelper.getOptionalFieldDictionaryId(config, 'UID_APPLICATION')
  };
};

export default function useCalculatorDictionaryEntries(config?: CalculatorPhase): CalculatorDictionaryState {

  const dictionaryIds: CalculatorDictionaryIds = useMemo(
    () => config ? getDictionaryIds(config) : {},
    [config]
  );

  const {dictionaries, loaded} = useDictionariesQuery(dictionaryIds);

  const dictionaryEntries = {
    advanceInterestApplication: getEntries(dictionaries, dictionaryIds?.advanceInterestApplication),
    creationType: getEntries(dictionaries, dictionaryIds?.creationType),
    paymentInterval: getEntries(dictionaries, dictionaryIds?.paymentInterval)
  };

  return {
    areDictionariesLoaded: loaded && areNotNil(values(dictionaryEntries)),
    dictionaryEntries: {
      ...dictionaryEntries,
      uidApplication: getEntries(dictionaries, dictionaryIds?.uidApplication)
    }
  };
}

