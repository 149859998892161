import {NxButton, NxButtonVariant} from '@nextbank/ui-components';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, {ReactElement} from 'react';
import {ReactComponent as AddIcon} from '../../../../../../assets/images/icon-add.svg';
import {CustomerType} from '../../../../../../shared/model/customer-type.model';
import {TransHelper} from '../../../../../../utils/trans-helper';
import {useGuardedHistory} from '../../../../../router/GuardedHistory';
import {ApplicationRelationshipType} from '../customer-data-form.model';
import {getRedirectPath} from './applicant-summary-utils';
import styles from './ApplicantsSummary.module.scss';
import ApplicantsSummaryTable from './ApplicantsSummaryTable';
import {RelatedPeopleStatus} from './related-people-status/RelatedPeopleStatus';

export const CUSTOMER_STEP_TRANS_PREFIX = 'LOAN_APPLICATIONS.CUSTOMER_DATA.CUSTOMER_DETAILS';
export const PrefixTrans = TransHelper.getPrefixedTrans(CUSTOMER_STEP_TRANS_PREFIX);

export interface ApplicantBasicData {
  key: string,
  businessName?: string;
  registrationDate?: string;
  firstName?: string,
  lastName?: string,
  middleName?: string,
  birthDate?: string,
  type: CustomerType;
  relationshipType: ApplicationRelationshipType;
}

interface RelatedPeopleConfig {
  customers: ApplicantBasicData[],
  min: number,
  max: number
}

interface Props {
  borrower?: ApplicantBasicData;
  coBorrowers: RelatedPeopleConfig;
  coMakers: RelatedPeopleConfig;
}

export default function ApplicantsSummary({borrower, coBorrowers, coMakers}: Props): ReactElement {

  const history = useGuardedHistory();

  const getOnClick = (relationship: ApplicationRelationshipType): void =>
    history.push(getRedirectPath(history.location.pathname, relationship));

  const getSectionTitle = (relationship: ApplicationRelationshipType): ReactElement => {
    switch (relationship) {
      case ApplicationRelationshipType.CO_BORROWER:
        return <PrefixTrans>CO_BORROWER</PrefixTrans>;
      case ApplicationRelationshipType.CO_MAKER:
        return <PrefixTrans>CO_MAKER</PrefixTrans>;
      case ApplicationRelationshipType.BORROWER:
        return <PrefixTrans>BORROWER</PrefixTrans>;
      default:
        throw new Error('Unrecognized applicant relationship');
    }
  };

  const borrowerTypeRow = (relationship: ApplicationRelationshipType, borrower?: ApplicantBasicData): ReactElement => (
    <div className={styles.customerDetails}>
      <div className={styles.header}>
        {getSectionTitle(relationship)}
        <NxButton className={styles.button}
                  disabled={!isNil(borrower)}
                  variant={NxButtonVariant.OUTLINED}
                  startIcon={<AddIcon />}
                  onClick={(): void => getOnClick(relationship)}>
          <PrefixTrans>ADD_CUSTOMER</PrefixTrans>
        </NxButton>
      </div>
      {!isNil(borrower) && <ApplicantsSummaryTable applicants={[borrower]} />}
    </div>
  );

  const relatedPeopleTypeRow = (relationship: ApplicationRelationshipType,
                                relatedPeople: RelatedPeopleConfig): ReactElement => {
    const customers = relatedPeople.customers;
    const customerAmount = customers?.length ?? 0;

    const isRelatedPeopleSufficient = customerAmount <= relatedPeople.max && customerAmount >= relatedPeople.min;

    return (
      <div className={styles.customerDetails}>
        <div className={styles.header}>
          <div className={styles.mainDetails}>
            {getSectionTitle(relationship)}
            <div className={styles.chip}>
              <RelatedPeopleStatus requiredAdded={isRelatedPeopleSufficient}
                                   min={relatedPeople.min}
                                   max={relatedPeople.max} />
            </div>
          </div>
          <NxButton className={styles.button}
                    variant={NxButtonVariant.OUTLINED}
                    startIcon={<AddIcon />}
                    disabled={customerAmount >= relatedPeople.max}
                    onClick={(): void => getOnClick(relationship)}>
            <PrefixTrans>ADD_CUSTOMER</PrefixTrans>
          </NxButton>
        </div>
        {!isEmpty(customers) && <ApplicantsSummaryTable applicants={customers} />}
      </div>
    );
  };

  return (
    <>
      {borrowerTypeRow(ApplicationRelationshipType.BORROWER, borrower)}
      {relatedPeopleTypeRow(ApplicationRelationshipType.CO_BORROWER, coBorrowers)}
      {relatedPeopleTypeRow(ApplicationRelationshipType.CO_MAKER, coMakers)}
    </>
  );
}

