import dayjs, {Dayjs} from 'dayjs';
import {CustomerType} from '../../../shared/model/customer-type.model';
import {LoanSimulation, LoanSimulationParams} from '../../../shared/model/loan-simulation.model';
import {PagedRecords} from '../../../shared/model/paged.model';
import {Applicant} from './steps/customer-data/customer-data-form.model';
import {CheckValueTO} from './steps/shared/custom-checks/check-value.model';

export interface Application {
  id: number;
  batchId?: number;
  currentPhaseId: number;
  referenceNo: string;
  status: ApplicationStatus;
  approvalStatus: ApprovalStatus;
  approvalExecutions: ApprovalExecution[];
  principalAmount: number;
  processId: number;
  createdBy: number;
  simulations: LoanApplicationSimulation[];
  agreement: Agreements;
  applicationData: LoanApplicationData;
  borrower?: Applicant<number>;
  coBorrowers?: Applicant<number>[];
  coMakers?: Applicant<number>[];
  checkValues: CheckValueTO[];
  repeated: boolean;
  preRelease: PreRelease;
  assignedTo: number;
}

export interface ApplicationBasicDataTO<T_DATE = string> {
  id: number;
  referenceNo: string;
  branchName: string;
  applicantNumber: string;
  applicantFirstName?: string;
  applicantMiddleName?: string;
  applicantLastName?: string;
  applicantBusinessName?: string;
  applicantType: CustomerType;
  currentPhaseId: number;
  currentPhaseName: string;
  productName: number;
  principalAmount: number;
  interestRate: number;
  totalAmortizationNumber: number;
  paymentInterval: string;
  creationType: string;
  processId: number;
  status: ApplicationStatus;
  approvalStatus: ApprovalStatus;
  lastModifiedBy?: string;
  createdOn: T_DATE;
  createdBy: string;
  modifiedOn?: T_DATE;
  assignedTo: string;
}

export type ApplicationBasicData = ApplicationBasicDataTO<Dayjs>;

export interface KeyedApplicationBasicData extends ApplicationBasicData {
  key: string;
}

export interface KeyedApplicationBasicDataByPage extends PagedRecords<KeyedApplicationBasicData> {
}

export interface ApplicationBasicDataByPageTO extends PagedRecords<ApplicationBasicDataTO> {
}

export const fromApplicationBasicDataTO = (applicationTO: ApplicationBasicDataTO): ApplicationBasicData => ({
  ...applicationTO,
  createdOn: dayjs(applicationTO.createdOn),
  modifiedOn: applicationTO.modifiedOn ? dayjs(applicationTO.modifiedOn) : undefined
});

export interface LoanApplicationData {
  applicationId?: number;
  borrowerTypeId: number;
  cicContractTypeId: number;
  id?: number;
  loanClassId: number;
  loanEconomicActivityId: number;
  loanPurposeId: number;
  loanPurposeToIndustryId: number;
  loanSecurityId: number;
  microfinanceClassificationId: number;
  misGroupId: number;
  transactionTypeId: number;
}

export interface LoanApplicationSimulation {
  id?: number;
  applicationId?: number;
  input: LoanSimulationParams;
  phaseId?: number;
  result: LoanSimulation;
}

export interface Agreements {
  id?: number;
  applicationId?: number;
  termsAndConditions: boolean;
}

export interface PreRelease {
  id?: number;
  applicationId?: number;
  comments?: string;
}

export enum ApplicationStatus {
  PENDING = 'PENDING',       // new
  SUBMITTED = 'SUBMITTED',  // submitted to CBS
  CANCELED = 'CANCELED',   // canceled by the user
  DEFERRED = 'DEFERRED',   // deferred by the user
  ERROR = 'ERROR'         // failed due to error
}

export enum ApprovalStatus {
  NOT_REQUIRED = 'NOT_REQUIRED',
  REQUIRED = 'REQUIRED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED'
}

export interface ApprovalExecutionInput {
  approverId?: number;
  comment?: string;
}

export interface ApprovalExecution extends ApprovalExecutionInput {
  applicationId: number;
  id: number;
  phaseId: number;
  status: ApprovalExecutionStatus;
  userFullName: string;
  userId: number;
}

export enum ApprovalExecutionStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED'
}
