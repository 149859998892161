export enum FileUploadErrorCode {
  FILE_TOO_LARGE
}

export type FileUploadError = {
  errorCode: FileUploadErrorCode,
  message: string,
}

export enum FileTypes {
  APPLICATION_PDF = 'application/pdf',
  IMAGE_PNG = 'image/png',
  IMAGE_JPG = 'image/jpeg',
  CSV = '.csv'
}
