import {NxLoader} from '@nextbank/ui-components';
import {Formik} from 'formik';
import isNil from 'lodash/isNil';
import mapValues from 'lodash/mapValues';
import React, {ReactElement, useContext, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {PhaseName} from '../../../../../constants/api-urls';
import {UrlParams} from '../../../../../routes/routes.model';
import useFileUpload from '../../../../../shared/hooks/use-file-upload.hook';
import usePost from '../../../../../shared/hooks/use-post.hook';
import useSingleQuery from '../../../../../shared/hooks/use-single-query.hook';
import {FileBasicData, NxFile} from '../../../../../shared/model/nx-file.model';
import {ApiHelper} from '../../../../../utils/api-helper';
import {handleFileUpload} from '../../../../../utils/file-upload-utils';
import {optionalValidationParams} from '../../../../../utils/optional-validation-form-utils';
import {StringHelper} from '../../../../../utils/string-helper';
import {TransHelper} from '../../../../../utils/trans-helper';
import {
  CustomerDataPhase
} from '../../../../loan-configurations/loan-configuration/steps/customer-data/customer-data-phase.model';
import {
  LoanApplicationDataPhase
} from '../../../../loan-configurations/loan-configuration/steps/loan-application-data/loan-application-data-phase.model';
import {LoanApplicationContext} from '../../LoanApplication';
import {handleCustomFieldValues} from '../shared/custom-checks/check-values-utils';
import {StepPayloadType} from '../shared/step-payload.model';
import {Address, AddressTO} from './addresses/address.model';
import {AddressFormEntries} from './addresses/GeolocationForm';
import {
  Applicant,
  CustomerDataFormFields,
  CustomersDataFormFields,
  IndividualCustomerProfilingPhasePayload
} from './customer-data-form.model';
import {getValidationSchema} from './customer-data-validation-schema';
import CustomerDataForm from './CustomerDataForm';
import {IdDocument, IdDocumentTO} from './documents/id-document.model';
import useCustomerData from './hooks/use-customer-data-start-data.hook';
import {getGeolocationByParentIdAndName} from '../../../../../utils/geolocation-helper';

export const CUSTOMER_STEP_TRANS_PREFIX = 'LOAN_APPLICATIONS.CUSTOMER_DATA';
export const PrefixTrans = TransHelper.getPrefixedTrans(CUSTOMER_STEP_TRANS_PREFIX);

const {getPhaseConfigurationUrl, getPhaseSaveUrl} = ApiHelper;

const CustomerData = (): ReactElement => {

  const {t} = useTranslation();
  const {uploadFile} = useFileUpload();
  const {applicationId} = useParams<UrlParams>();
  const {processId, application, setOpenedPhaseId} = useContext(LoanApplicationContext);
  const phaseUrl = getPhaseConfigurationUrl(processId, PhaseName.INDIVIDUAL_CUSTOMER_PROFILING);
  const saveStep = usePost<void, IndividualCustomerProfilingPhasePayload>(getPhaseSaveUrl(applicationId));
  const config = useSingleQuery<CustomerDataPhase>(phaseUrl);

  const loanApplicationDataPhaseUrl = getPhaseConfigurationUrl(processId, PhaseName.LOAN_APPLICATION_DATA);
  const loanApplicationDataConfig = useSingleQuery<LoanApplicationDataPhase>(loanApplicationDataPhaseUrl);

  const {customerData, customerDataLoaded} = useCustomerData(
    config, application?.borrower, application?.coMakers, application?.coBorrowers
  );

  useEffect(() => {
    if (config) {
      setOpenedPhaseId(config.id);
    }
  }, [config]);

  const submit = async (values: CustomersDataFormFields): Promise<void> => {
    const borrower = await extractApplicantData(values.borrower, customerData?.dictionaryEntries);
    let customerDataPayload: IndividualCustomerProfilingPhasePayload = {
      type: StepPayloadType.CUSTOMER_DATA,
      borrower
    };

    let coMakers: Applicant[];
    if (values.coMakers) {
      coMakers = (await Promise.all(values.coMakers
        .map(value => extractApplicantData(value), customerData?.dictionaryEntries)
      )).filter(result => !isNil(result)) as Applicant[];
      customerDataPayload = {
        ...customerDataPayload,
        coMakers
      };
    }

    let coBorrowers: Applicant[];
    if (values.coBorrowers) {
      coBorrowers = (await Promise.all(values.coBorrowers
        .map(value => extractApplicantData(value, customerData?.dictionaryEntries))
      )).filter(result => !isNil(result)) as Applicant[];
      customerDataPayload = {
        ...customerDataPayload,
        coBorrowers
      };
    }

    return saveStep(customerDataPayload, null, optionalValidationParams(values.validate));
  };

  const extractApplicantData = async (data?: CustomerDataFormFields,
                                      addressEntries?: AddressFormEntries): Promise<Applicant | undefined> => {
    if (isNil(data)) {
      return;
    }

    const handleUpload = (file: NxFile): Promise<FileBasicData> => handleFileUpload(file, uploadFile);

    const individualDataValues = mapValues(data?.individualData, 'value');
    let picture = individualDataValues.picture ? await handleUpload(individualDataValues.picture) : undefined;
    let signature = individualDataValues.signature ? await handleUpload(individualDataValues.signature) : undefined;

    const getDosriType = (): string => {
      return StringHelper.stringToBoolean(individualDataValues.dosri)
        ? individualDataValues.dosriTypeId
        : undefined;
    };

    const individualData = {
      ...individualDataValues,
      ...picture ? {picture} : {},
      ...signature ? {signature} : {},
      dosriTypeId: getDosriType(),
      id: data.individualDataId,
      applicantId: data.id,
      // Check if number below exists. Eliminates empty string returned after zeroing field value.
      mobileNumber: StringHelper.removeWhitespaces(individualDataValues.mobileNumber),
      workNumber: StringHelper.removeWhitespaces(individualDataValues.workNumber),
      landlineNumber: StringHelper.removeWhitespaces(individualDataValues.workNumber)
    };

    const corporateDataValues = mapValues(data?.corporateData, 'value');
    picture = corporateDataValues.picture ? await handleUpload(corporateDataValues.picture) : undefined;
    signature = corporateDataValues.signature
      ? await handleUpload(corporateDataValues.signature)
      : undefined;

    const corporateData = {
      ...corporateDataValues,
      ...picture ? {picture} : {},
      ...signature ? {signature} : {},
      dosriTypeId: getDosriType(),
      id: data?.corporateDataId,
      applicantId: data.id,
      // Check if number below exists. Eliminates empty string returned after zeroing field value.
      phoneNumber: StringHelper.removeWhitespaces(corporateDataValues.phoneNumber)
    };

    const mapDocument = (document: IdDocument): Promise<IdDocumentTO> =>
      Promise.all(document.scans.map(handleUpload))
        .then(scans => ({...document, scans}));

    const idDocuments = await Promise.all(data.idDocuments.map(mapDocument));
    const customFieldValues = await Promise.all(
      handleCustomFieldValues(data.customFieldValues, uploadFile)
    );

    const extraNumber = !isNil(data.extraNumber.value) ? data.extraNumber.value : undefined;
    return {
      id: data.id,
      type: data.type,
      relationshipType: data.relationshipType,
      sourceNumber: data.sourceNumber,
      sourceId: data.sourceId,
      individualData,
      corporateData,
      addresses: data.addresses.map(address => extractAddress(address, addressEntries)),
      incomeSources: data.incomeSources,
      idDocuments,
      customFieldValues,
      extraNumber
    } as unknown as Applicant;
  };

  const extractAddress = (address: Address, addressEntries?: AddressFormEntries): AddressTO => {
    if (isNil(addressEntries)) {
      return address;
    }
    
    const selectedProvinceEntry = addressEntries.province?.find(entry => entry.id === address.provinceId);
    const selectedCityEntry = getGeolocationByParentIdAndName(addressEntries.city, selectedProvinceEntry?.id, address.city);
    const selectedBarangayEntry = getGeolocationByParentIdAndName(addressEntries.barangay, selectedCityEntry?.id, address.barangay);

    return {
      ...address,
      geolocationId: selectedBarangayEntry?.id
    };
  };

  const initialValues =
    useMemo(() => customerDataLoaded && !isNil(customerData) ? {
        borrower: customerData.borrowerData,
        coBorrowers: customerData.coBorrowersData,
        coMakers: customerData.coMakersData
      } : undefined,
      [customerDataLoaded, config, application]);

  //TODO check how many times hooks are called
  return !customerDataLoaded || isNil(config) || isNil(loanApplicationDataConfig) ||
  isNil(customerData) || isNil(initialValues)
    ? <NxLoader />
    : <Formik<CustomersDataFormFields>
      onSubmit={submit}
      enableReinitialize
      validateOnChange={false}
      initialValues={initialValues}
      validationSchema={getValidationSchema(initialValues, t)}
    >
      {
        (
          (props): ReactElement =>
            <CustomerDataForm formikProps={props}
                              config={config}
                              loanApplicationDataConfig={loanApplicationDataConfig}
                              customerData={customerData} />
        )
      }
    </Formik>;
};

export default CustomerData;

