import {debounce} from '@material-ui/core';
import {NxLoader} from '@nextbank/ui-components';
import {Form, Formik, FormikProps} from 'formik';
import {isNil} from 'lodash';
import React, {ReactElement, useCallback, useContext, useEffect, useState, createContext} from 'react';
import {SecurityContext} from '../../../App';
import {
  APPLICATIONS,
  AWAITING_APPROVAL_APPLICATIONS_URL,
  TO_RELEASE_APPLICATIONS_URL,
  TO_UPDATE_APPLICATIONS_URL
} from '../../../constants/api-urls';
import {REFRESH_DATA_DEBOUNCE_DELAY} from '../../../constants/table-defaults';
import useGet from '../../../shared/hooks/use-get.hook';
import {generateKey} from '../../../utils/generate-key';
import {AppSnackbarContext} from '../../shared/app-snackbar-provider/AppSnackbarProvider';
import {
  ApplicationBasicDataByPageTO,
  ApplicationBasicDataTO,
  fromApplicationBasicDataTO,
  KeyedApplicationBasicData,
  KeyedApplicationBasicDataByPage
} from '../loan-application/loan-application.model';
import {getUrlParams} from './loan-application-dashboard-helper';
import {FilterValues, TableType} from './loan-application-dashboard.model';
import LoanApplicationsTable from './loan-applications-table/LoanApplicationsTable';
import styles from './LoanApplicationDashboard.module.scss';
import ApplicationsSearchBar from './search/ApplicationsSearchBar';
import {ApplicationBasicData} from '../loan-application/loan-application.model';
import {SelectedApplicationsContextType} from './selected-application-context';

const mapToKeyedApplication = (applicationTO: ApplicationBasicDataTO): KeyedApplicationBasicData => ({
  ...fromApplicationBasicDataTO(applicationTO),
  ...generateKey()
});

export const SelectedApplicationsContext = createContext<SelectedApplicationsContextType>({
  selectedApplications: [],
  setSelectedApplication: () => null,
  refreshTableData: () => null,
});

export const filtersDefaultState = {
  applicationStatuses: [],
  approvalStatuses: [],
  tableType: TableType.MY_APPLICATIONS
};

const LoanApplicationDashboardTable = (): React.ReactElement => {

  const {showErrorMessage} = useContext(AppSnackbarContext);
  const {userData} = useContext(SecurityContext);
  const getApplications = useGet<ApplicationBasicDataByPageTO>(APPLICATIONS);
  const getToApprove = useGet<ApplicationBasicDataByPageTO>(AWAITING_APPROVAL_APPLICATIONS_URL);
  const getToRelease = useGet<ApplicationBasicDataByPageTO>(TO_RELEASE_APPLICATIONS_URL);
  const getToUpdate = useGet<ApplicationBasicDataByPageTO>(TO_UPDATE_APPLICATIONS_URL);
  const [applications, setApplications] = useState<KeyedApplicationBasicDataByPage>();
  const [isSearching, setSearching] = useState<boolean>(false);
  const [selectedApplications, setSelectedApplication] = useState<ApplicationBasicData[]>([]);

  const getApplicationsPage = (appliedFilters: FilterValues, pageNo = 0): Promise<void> => {
    setSearching(true);
    const params = getUrlParams(appliedFilters, userData, pageNo);

    let fetchMethod: Promise<ApplicationBasicDataByPageTO>;
    switch (appliedFilters.tableType) {
      case TableType.MY_APPLICATIONS:
      case TableType.ASSIGNED_TO_ME:
      case TableType.ALL_APPLICATIONS:
        fetchMethod = getApplications(params);
        break;
      case TableType.TO_APPROVE:
        fetchMethod = getToApprove(params);
        break;
      case TableType.TO_UPDATE:
        fetchMethod = getToUpdate(params);
        break;
      case TableType.TO_RELEASE:
        fetchMethod = getToRelease(params);
        break;
      default:
        throw Error('Unknown table type');
    }

    return fetchMethod
      .then(({result, ...page}) =>
        setApplications({...page, result: result.map(mapToKeyedApplication)}))
      .catch(error => showErrorMessage(error.message))
      .finally(() => setSearching(false));
  };

  const onSearch = (changedFilters: FilterValues): Promise<void> => getApplicationsPage(changedFilters);
  const debouncedOnSubmit = useCallback(debounce(onSearch, REFRESH_DATA_DEBOUNCE_DELAY), []);

  const ResultsTableWithSearch = ({values}: FormikProps<FilterValues>): ReactElement => {
    const changePage = (pageNo: number): Promise<void> => getApplicationsPage(values, pageNo);

    const refreshTableData = () => {
      debouncedOnSubmit(values);
    };

    useEffect(() => {
      refreshTableData();
    }, [values]);

    return (
      <SelectedApplicationsContext.Provider value={{selectedApplications, setSelectedApplication, refreshTableData}}>
        <Form>
          <ApplicationsSearchBar />
          {
            isSearching || isNil(applications)
              ? <NxLoader />
              : <LoanApplicationsTable applications={applications}
                                       onPageChange={changePage} />
          }
        </Form>
      </SelectedApplicationsContext.Provider>
    );
  };

  return (
    <div className={styles.container}>
      <Formik<FilterValues> onSubmit={onSearch} initialValues={filtersDefaultState}>
        {ResultsTableWithSearch}
      </Formik>
    </div>
  );
};

export default LoanApplicationDashboardTable;
