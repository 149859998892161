import {useEffect} from 'react';
import {CalculatorFormFields} from '../../../calculator/calculator.model';
import {CALCULATE_FIRST_PAYMENT_DATE_URL} from '../../../../../../../constants/api-urls';
import {DictionaryHelper} from '../../../../../../../utils/dictionary-helper';
import {DictionaryEntry} from '../../../../../../../shared/model/dictionary.model';
import useGet from '../../../../../../../shared/hooks/use-get.hook';

const {getEntryCodeById} = DictionaryHelper;

export function useCalculateFirstPaymentDate(values: CalculatorFormFields,
                                             onFirstPaymentDateCalculated: (value) => void,
                                             paymentIntervalDictionaryEntries?: DictionaryEntry[]): void {

  const getCalculateFirstPaymentDate = useGet<string>(CALCULATE_FIRST_PAYMENT_DATE_URL);

  useEffect(() => {
    const dateGranted = values.dateGranted.value;
    const paymentInterval = values.paymentInterval.value;
    if (dateGranted && paymentInterval && paymentIntervalDictionaryEntries) {
      const urlSearchParams = getUrlParams(dateGranted, paymentInterval, paymentIntervalDictionaryEntries);
      getCalculateFirstPaymentDate(urlSearchParams).then(firstPaymentDate => {
        const firstPaymentDateCopy = {...values.firstPaymentDate, value: firstPaymentDate};
        onFirstPaymentDateCalculated(firstPaymentDateCopy);
      });
    }
  }, [values.dateGranted.value, values.paymentInterval.value]);
}

function getUrlParams(dateGranted: string,
                      paymentInterval: string,
                      paymentIntervalDictionaryEntries: DictionaryEntry[]): URLSearchParams {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.set('grantDate', dateGranted);
  urlSearchParams.set('paymentInterval', getEntryCodeById(paymentInterval, paymentIntervalDictionaryEntries));
  return urlSearchParams;
}
