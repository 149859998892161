// Values order is important as it is read by Object.values and passed as table headers.
// Changing order will change order of related table headers
export enum LoanApplicationTableHeaders {
  BRANCH_NAME = 'BRANCH_NAME',
  REFERENCE_NO = 'REFERENCE_NO',
  CIF = 'CIF',
  APPLICANT_NAME = 'APPLICANT_NAME',
  PRODUCT_NAME = 'PRODUCT_NAME',
  PRINCIPAL_AMOUNT = 'PRINCIPAL_AMOUNT',
  INTEREST_RATE = 'INTEREST_RATE',
  ASSIGNED_TO = 'ASSIGNED_TO',
  STATUS = 'STATUS',
  ACTIONS_COLUMN = ''
}

enum LoanApplicationCheckBoxTableHeader {
  CHECK_BOX_COLUMN = ''
}

export const LoanApplicationWithCheckBoxTableHeaders = {
  ...LoanApplicationCheckBoxTableHeader,
  ...LoanApplicationTableHeaders
}

export enum LoanApplicationLabels {
  PAYMENT_INTERVAL = 'PAYMENT_INTERVAL',
  TOTAL_AMORTIZATION_NUMBER = 'TOTAL_AMORTIZATION_NUMBER',
  CREATED_BY = 'CREATED_BY',
  MODIFIED_BY = 'MODIFIED_BY',
  MODIFIED_ON = 'MODIFIED_ON',
  CREATED_ON = 'CREATED_ON',
  APPROVAL_STATUS = 'APPROVAL_STATUS',
  CREATION_TYPE = 'CREATION_TYPE'
}
