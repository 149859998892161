import {NxFormikSelect, NxSelectOption} from '@nextbank/ui-components';
import React, {ReactElement, useContext} from 'react';
import {ConfiguredField} from '../../../../../../shared/model/field.model';
import {StepContext} from '../loan-application-step/LoanApplicationStep';
import {ConfiguredFieldWrapper} from './ConfiguredFieldWrapper';
import {FieldDisablementReason} from './field-disablement-reason.model';

interface Props {
  field: ConfiguredField | ConfiguredField<number>;
  label: React.ReactNode;
  name: string;
  options?: NxSelectOption[];
  disablementReason?: FieldDisablementReason;
  emptyOption?: boolean;
  hint?: React.ReactNode;
}

export const ConfiguredFieldSelect = (
  {name, field, label, options, disablementReason, emptyOption = true, hint}: Props
): ReactElement => {

  const {isStepReadonly} = useContext(StepContext);
  const {required, enabled} = field;

  const props = {
    disabled: isStepReadonly || !!disablementReason,
    options: options ? options : [],
    name: `${name}.value`,
    required,
    label
  };

  return (
    <ConfiguredFieldWrapper enabled={enabled} disablementReason={disablementReason} hint={hint}>
      <NxFormikSelect {...props} emptyOption={emptyOption} />
    </ConfiguredFieldWrapper>
  );
};
