import {useField} from 'formik';
import React, {ReactElement, useContext, useMemo} from 'react';
import {ACCEPTED_IMAGE_FILE_TYPES, MAXIMUM_IMAGE_FILE_SIZE} from '../../../../../../constants/file-uploads';
import {ConfiguredField} from '../../../../../../shared/model/field.model';
import {NxFile} from '../../../../../../shared/model/nx-file.model';
import NxFileUpload from '../../../../../shared/inputs/nx-file-upload/NxFileUpload';
import {CustomFieldValue} from '../../customer-data/custom-fields/custom-field.model';
import {CheckValue} from '../custom-checks/check-value.model';
import {StepContext} from '../loan-application-step/LoanApplicationStep';
import {FieldDisablementReason} from './field-disablement-reason.model';
import {ConfiguredFieldWrapper} from './ConfiguredFieldWrapper';
import styles from './ConfiguredFieldFileInput.module.scss';

interface ConfiguredFieldError {
  value?: {
    file: string;
    isChanged: boolean;
  };
  enabled?: string;
  required?: string;
  name?: string;
}

interface Props {
  label: React.ReactNode;
  name: string;
  configuredField: ConfiguredField<NxFile>;
  disablementReason?: FieldDisablementReason;
}

type CustomFileFieldValue = CustomFieldValue<NxFile> | CheckValue<NxFile>;

export const ConfiguredFieldFileInput = ({label, name, configuredField, disablementReason}: Props): ReactElement => {

  const {isStepReadonly} = useContext(StepContext);
  const {enabled, required} = configuredField;
  const [field, meta, helper] = useField<CustomFileFieldValue>(name);
  const nxFile = useMemo(() => field.value?.value, [field.value]);

  const handleChange = (file?: File): Promise<void> | void => {

    const newValue = {
      ...field.value,
      value: {file, isChanged: true}
    };

    helper.setValue(newValue);
  };

  const externalError = (meta.error as ConfiguredFieldError)?.value?.file;

  return (
    <ConfiguredFieldWrapper enabled={enabled} disablementReason={disablementReason} className={styles.fileUploadWrapper}>
      <NxFileUpload disabled={isStepReadonly || !!disablementReason}
                    acceptedFileTypes={ACCEPTED_IMAGE_FILE_TYPES}
                    maximumFileSize={MAXIMUM_IMAGE_FILE_SIZE}
                    className={styles.fileUpload}
                    externalError={externalError}
                    onBlur={(): void => helper.setTouched(true)}
                    onChange={handleChange}
                    value={nxFile?.file}
                    required={required}
                    label={label} />
    </ConfiguredFieldWrapper>
  );
};
