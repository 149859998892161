import {keys, mapValues, some} from 'lodash';
import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';
import {
  CalculatorFormFields
} from '../../components/loan-applications/loan-application/steps/calculator/calculator.model';
import {DictionaryEntry} from '../../shared/model/dictionary.model';
import {LoanSimulationParams} from '../../shared/model/loan-simulation.model';
import {DictionaryHelper} from '../dictionary-helper';
import {ConfiguredFieldRecords} from './configured-fields-utils';
import {
  ExternalApplicationData,
  ExternalApplicationOrigination,
  RmbSdeApplicationDataTO
} from '../../shared/model/external-application.model';
import {Process} from '../../shared/model/process.model';
import {PaymentIntervalOptionsProps, PaymentIntervalEntryCode} from '../../shared/hooks/use-payment-interval-options.hook';
import {LoanApplicationDataFormFields} from '../../components/loan-applications/loan-application/steps/loan-application-data/loan-application-data-form.model';

interface DictionaryEntries {
  advanceInterestApplication?: DictionaryEntry[];
  creationType?: DictionaryEntry[];
  paymentInterval?: DictionaryEntry[];
  uidApplication?: DictionaryEntry[];
}

export const getSimulationDataBasedOnFields =
  (fields: CalculatorFormFields, dictionaryEntries: DictionaryEntries, processId: number): LoanSimulationParams => {

    const {
      advanceInterestApplication,
      advanceInterestNo,
      creationType,
      dateGranted,
      overrideAmortizationAmount,
      diminishingAmortizationNumber,
      interestRate,
      paymentInterval,
      principal,
      totalAmortizationNumber,
      firstPaymentDate,
      interestCalculationParameter,
      uidAmortizationNumber,
      uidApplication
    } = mapValues(fields, 'value');

    const getTotalAmortizationNumber = (): number => {
      return typeof totalAmortizationNumber != 'string' ? totalAmortizationNumber : Number(totalAmortizationNumber);
    };

    return {
      advanceInterestNo,
      overrideAmortizationAmount,
      diminishingAmortizationNumber,
      firstPaymentDate,
      grantDate: dateGranted,
      interestRate,
      productTypeId: processId,
      principalAmount: principal,
      totalAmortizationNumber: getTotalAmortizationNumber(),
      creationType: getOptionalEntryCodeById(creationType, dictionaryEntries.creationType),
      paymentInterval: getOptionalEntryCodeById(paymentInterval, dictionaryEntries.paymentInterval),
      advanceInterestApplication:
        getOptionalEntryCodeById(advanceInterestApplication, dictionaryEntries.advanceInterestApplication),
      interestCalculationParameter,
      uidAmortizationNumber,
      uidApplication: getOptionalEntryCodeById(uidApplication, dictionaryEntries.uidApplication),
      simulatedFees: fields.simulation?.input.simulatedFees
    };
  };

export const updateConfiguredFieldsWithSavedData =
  (fields: ConfiguredFieldRecords, entries: DictionaryEntries, savedData: Record<string, unknown>): void => {
    forEach(savedData, (value, key): void => {

      // update values only for fields corresponding with saved data
      if (!fields[key] || value === undefined) {
        return;
      }

      if (isNil(value)) {
        fields[key].value = value;
        return;
      }

      const isDictionaryType = some(keys(entries), entryKey => entryKey === key);
      fields[key].value = isDictionaryType ? DictionaryHelper.getEntryIdByCode(value, entries[key]) : String(value);
    });
  };

function getOptionalEntryCodeById(id?: number | string, entries?: DictionaryEntry[]): string | undefined {
  return id && entries ? DictionaryHelper.getEntryCodeById(id, entries) : undefined;
}

export const overrideInitialValuesWithExternalApplicationData = (
  externalApplicationData: ExternalApplicationData | undefined,
  initialValues: CalculatorFormFields | LoanApplicationDataFormFields,
  paymentIntervalEntries: DictionaryEntry[] | undefined,
  process: Process,
  paymentIntervalOptionsProps: PaymentIntervalOptionsProps
): void => {
  // If the loan application was originated from an external system, override initial form values
  if (!isNil(externalApplicationData)) {
    if (externalApplicationData.origination === ExternalApplicationOrigination.RMB_SDE) {
      const data = externalApplicationData.data as RmbSdeApplicationDataTO;
      const paymentInterval = paymentIntervalEntries?.find((entry) => entry.code === PaymentIntervalEntryCode.MONTHLY_ENTRY_CODE);
      const hasMonthlyPaymentIntervalOption = paymentIntervalOptionsProps.paymentIntervalOptions?.some(option => option.value === String(paymentInterval?.id));

      initialValues.principal.value = data.principal;
      initialValues.interestRate.value = process.loanProduct.minInterestRate;
      initialValues.totalAmortizationNumber.value = data.loanTerm;

      if (paymentInterval && hasMonthlyPaymentIntervalOption) {
        initialValues.paymentInterval.value = String(paymentInterval.id);
      }
    }
  }
};