import {NxButton, NxButtonVariant, NxLoader} from '@nextbank/ui-components';
import clsx from 'clsx';
import isNil from 'lodash/isNil';
import React, {ReactElement, useContext, useEffect} from 'react';
import {Trans} from 'react-i18next';
import {useParams} from 'react-router';
import {ReactComponent as PrintIcon} from '../../../../../assets/images/icon-print.svg';
import {PhaseName} from '../../../../../constants/api-urls';
import {UrlParams} from '../../../../../routes/routes.model';
import usePost from '../../../../../shared/hooks/use-post.hook';
import useSingleQuery from '../../../../../shared/hooks/use-single-query.hook';
import {Phase} from '../../../../../shared/model/phase.model';
import {PrintType} from '../../../../../shared/model/print.model';
import {ApiHelper} from '../../../../../utils/api-helper';
import {TransHelper} from '../../../../../utils/trans-helper';
import {SystemContext} from '../../../../shared/system-context-provider/SystemContextProvider';
import {LoanApplicationContext} from '../../LoanApplication';
import LoanApplicationStep from '../shared/loan-application-step/LoanApplicationStep';
import usePrint from '../shared/loan-application-step/print-button/usePrint';
import ReleaseButton from './release-button/ReleaseButton';
import styles from './Summary.module.scss';

export const TRANS_PREFIX = 'LOAN_APPLICATIONS.SUMMARY';
export const PrefixTrans = TransHelper.getPrefixedTrans(TRANS_PREFIX);
const {getApplicationUrl, getPhaseConfigurationUrl} = ApiHelper;

interface StepRequiredData {
  config: Phase;
}

export default function Summary(): ReactElement {

  const {processId, setOpenedPhaseId} = useContext(LoanApplicationContext);
  const {printsEnabled} = useContext(SystemContext);
  const phaseUrl = getPhaseConfigurationUrl(processId, PhaseName.SUMMARY);
  const config = useSingleQuery<Phase>(phaseUrl);
  const {applicationId} = useParams<UrlParams>();

  useEffect(() => {
    if (config) {
      setOpenedPhaseId(config.id);
    }
  }, [config]);
  
  const SummaryStep = ({config}: StepRequiredData): ReactElement => {

    const {onPrint, isGenerating} = usePrint(parseInt(applicationId), config.id, PrintType.SUMMARY);
    const submitApplication = usePost(`${getApplicationUrl(applicationId)}/submit`);

    return <LoanApplicationStep stepNavigation={<ReleaseButton submitApplicationRequest={submitApplication} />}
                                stepInstruction={<PrefixTrans>INSTRUCTION</PrefixTrans>}
                                phaseId={config.id}>
      {
        printsEnabled && <div className={styles.printApplication}>
          <Trans>{TRANS_PREFIX}.PRINT_ALL</Trans>
          <NxButton className={clsx(styles.printButton)}
                    startIcon={<PrintIcon />}
                    variant={NxButtonVariant.OUTLINED}
                    onClick={onPrint}
                    loaded={!isGenerating}>
            <Trans>COMMON.PRINT</Trans>
          </NxButton>
        </div>
      }
    </LoanApplicationStep>;
  };

  return isNil(config)
    ? <NxLoader />
    : <SummaryStep config={config} />;
}
