import {UrlParam} from '../../../../routes/routes.model';
import {RoutePaths} from '../../../../routes/routes.paths';
import {buildApplicationStepPath} from '../../../../utils/loan-steps-utils';
import Agreements from '../steps/agreements/Agreements';
import Calculator from '../steps/calculator/Calculator';
import CustomPhase from '../steps/custom-phase/CustomPhase';
import CustomerData from '../steps/customer-data/CustomerData';
import EligibilityCriteria from '../steps/eligibility-criteria/EligibilityCriteria';
import LoanApplicationData from '../steps/loan-application-data/LoanApplicationData';
import PreRelease from '../steps/pre-release/PreRelease';
import Summary from '../steps/summary/Summary';

export const LOAN_APPLICATION_ROUTES = [
  {
    path: RoutePaths.CALCULATOR,
    key: 'APPLICATION_CALCULATOR',
    component: Calculator
  },
  {
    path: RoutePaths.CUSTOMER_DATA,
    key: 'APPLICATION_CUSTOMER_DATA',
    component: CustomerData
  },
  {
    path: RoutePaths.ELIGIBILITY_CRITERIA,
    key: 'APPLICATION_ELIGIBILITY_CRITERIA',
    component: EligibilityCriteria
  },
  {
    path: RoutePaths.AGREEMENTS,
    key: 'APPLICATION_AGREEMENTS',
    component: Agreements
  },
  {
    path: RoutePaths.LOAN_APPLICATION_DATA,
    key: 'APPLICATION_LOAN_APPLICATION_DATA',
    component: LoanApplicationData
  },
  {
    path: RoutePaths.PRE_RELEASE,
    key: 'APPLICATION_PRE_RELEASE',
    component: PreRelease
  },
  {
    path: RoutePaths.SUMMARY,
    key: 'APPLICATION_SUMMARY',
    component: Summary
  },
  {
    path: `${RoutePaths.CUSTOM}/:${UrlParam.PHASE_ID}`,
    key: 'APPLICATION_CUSTOM',
    component: CustomPhase
  }]
  .map(route => ({
    ...route,
    path: buildApplicationStepPath(route.path)
  }));
