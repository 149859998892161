import {
  NxButton,
  NxFormikInput,
  NxFormikSelect,
  NxLoader
} from '@nextbank/ui-components';
import {Form, Formik, FormikProps} from 'formik';
import isNil from 'lodash/isNil';
import React, {ReactElement, useContext, useMemo, useState} from 'react';
import {Trans} from 'react-i18next';
import {CATEGORIES_URL} from '../../../../../constants/api-urls';
import {ACCEPTED_IMAGE_FILE_TYPES, MAXIMUM_IMAGE_FILE_SIZE} from '../../../../../constants/file-uploads';
import useFileUpload from '../../../../../shared/hooks/use-file-upload.hook';
import usePost from '../../../../../shared/hooks/use-post.hook';
import usePut from '../../../../../shared/hooks/use-put.hook';
import {Category} from '../../../../../shared/model/category.model';
import {handleFileUpload} from '../../../../../utils/file-upload-utils';
import {SelectHelper} from '../../../../../utils/select-helper';
import NxFileUpload from '../../../../shared/inputs/nx-file-upload/NxFileUpload';
import {CategoriesContext, PrefixTrans} from '../../Categories';
import {EditCategoryFormFields as FormFields, ICON_FILE} from './edit-category-form.model';
import useCategoryIconFile from './use-category-icon-file.hook';
import styles from './EditCategoryForm.module.scss';
import isEmpty from 'lodash/isEmpty';

const {mapCategoriesToOptions} = SelectHelper;

type NewCategoryPayload = Omit<Category, 'id'>;

interface Props {
  category?: Category;
  onSave: () => void;
}

export default function EditCategoryForm({category, onSave}: Props): ReactElement {

  const {id, image, name, parentCategoryId} = category ?? {};
  const {iconFile, isIconFileLoading} = useCategoryIconFile(image);
  const {uploadFile} = useFileUpload();
  const {categories} = useContext(CategoriesContext);
  const createCategory = usePost<void, NewCategoryPayload>(CATEGORIES_URL);
  const updateCategory = usePut<void, Category>(`${CATEGORIES_URL}/${id}`);
  const [error, setError] = useState<string>();

  const submit = async (fields: FormFields): Promise<void> => {

    const image = fields.iconFile ? await handleFileUpload(fields.iconFile, uploadFile) : undefined;

    const data = {
      ...fields,
      image
    };

    const saveFunction = (): Promise<void> => !isNil(category)
      ? updateCategory({...category, ...data})
      : createCategory(data);

    return saveFunction()
      .then(onSave)
      .catch(error => setError(error.message));
  };

  const parentCategoryOptions = useMemo(() =>
      mapCategoriesToOptions((categories ?? []).filter(category => category.id !== id)),
    [categories, id])

  const EditCategoryForm = ({values, isSubmitting, setFieldValue}: FormikProps<FormFields>): ReactElement => {

    const onFileChange = (file?: File): Promise<void> | void => setFieldValue(ICON_FILE, {file, isChanged: true});

    return (
      <Form>
        <NxFormikInput label={<PrefixTrans>NAME</PrefixTrans>}
                       className={styles.input}
                       name='name'
                       required />
        <NxFormikSelect<number> label={<PrefixTrans>PARENT</PrefixTrans>}
                                disabled={isEmpty(parentCategoryOptions)}
                                options={parentCategoryOptions ?? []}
                                className={styles.input}
                                name='parentCategoryId' />
        <NxFileUpload acceptedFileTypes={ACCEPTED_IMAGE_FILE_TYPES}
                      maximumFileSize={MAXIMUM_IMAGE_FILE_SIZE}
                      onChange={onFileChange}
                      value={values.iconFile?.file} />
        {
          error && <div className={styles.error}>{error}</div>
        }
        <NxButton type='submit'
                  className={styles.button}
                  loaded={!isSubmitting}
                  disabled={isSubmitting || !values.name} >
          <Trans>COMMON.SAVE</Trans>
        </NxButton>
      </Form>
    );
  };

  return isIconFileLoading
    ? <NxLoader />
    : <Formik<FormFields>
        onSubmit={submit}
        initialValues={{name: name ?? '', parentCategoryId, iconFile}}>
        {EditCategoryForm}
      </Formik>;
}
